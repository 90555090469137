import uuid from "react-uuid";
import { getCurrentCustomerId } from "Util/Utils";

const { Storage } = require("aws-amplify");

export const StorageFolders = {
  images: "images/",
  files: "files/",
  locales: "locales/"
};

export async function NewDownloadFunction(path, filename){
  console.log("NewDownloadFunction - path", path);
  
  const fileUrl = await Storage.get(path);
  console.log("NewDownloadFunction - fileUrl", fileUrl);

  const file = await fetch(fileUrl);
  console.log("NewDownloadFunction - file", file);

  const fileBlob = await file.blob();

  console.log("NewDownloadFunction - fileBlob", fileBlob);

  var url = window.URL.createObjectURL(fileBlob);
  var a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();    
  a.remove();  //afterwards we remove the element again         
}

export function DownloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

export async function getPathFromStorage(path) {
  const url = await Storage.get(path);
  return url;
}

export async function listStorage() {
  return await Storage.list("/", { level: "public" });
}

export async function deleteFile(filePath) {
  try {
    await Storage.remove(filePath, { level: "public" });
    return true;
  } catch (error) {
    console.error("error uploading material: ", error);
  }
  return false;
}

export async function uploadImage(file) {
  const path = StorageFolders.files + uuid() + "." + file.name.split(".").pop();
  try {
    await Storage.put(path, file, { level: "public" });
    return path;
  } catch (error) {
    console.error("error uploading image: ", error);
    return null;
  }
}

export async function getImage(imagePath) {
  try {
    let list = await Storage.list("", { level: "public" });

    if (list.find((file) => file.key === imagePath) != null) {
      let url = await Storage.get(imagePath);
      return url;
    }
    return null;
  } catch (err) {
    console.error("Getimage", err);
  }
}

export async function uploadFileWithOriginalFileName(folder, file) {
  const path = StorageFolders.files + folder + "/" + file.name;

  try {
    await Storage.put(path, file, { level: "public" });
    return [file.name, path];
  } catch (error) {
    console.error("error uploading image: ", error);
    return null;
  }
}

export async function uploadFile(file) {
  const customerId = await getCurrentCustomerId();
  const path = StorageFolders.files + customerId + "/" + uuid() + "." + file.name.split(".").pop();

  try {
    await Storage.put(path, file, { level: "public" });
    return [file.name, path];
  } catch (error) {
    console.error("error uploading image: ", error);
    return null;
  }
}

export async function uploadLocaleFile(language, file) {
  const fullPath = StorageFolders.locales + language + "/" + file.name;

  try {
    await Storage.put(fullPath, file, { level: "public" });
    return [file.name, fullPath];

  } catch (error) {
    console.error("Error uploading locale file: ", error);
    return null;
  }
}

export async function getFileUrl(filePath) {
  try {
    let list = await Storage.list("", { level: "public" });

    if (list.results.find((file) => file.key === filePath) != null) {
      let url = await Storage.get(filePath);
      return url;
    }
  } catch (err) {
    console.error("getFileUrl", err);
    return null;
  }
}

export async function getMultipleFileUrl(files) {
  try {
    let list = await Storage.list("", { level: "public" });

    let filesWithUrl = [];
    for (let file of files) {

      const s3File = list.results.find((f) => f.key === file.path);

      if (s3File != null) {
        filesWithUrl.push({ ...file, url: await Storage.get(file.path) });
      }
    }
    return filesWithUrl;
  } catch (err) {
    console.error("getFile", err);
    return [];
  }
}
