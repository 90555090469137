import Auth from "@aws-amplify/auth";
//import { green } from "@mui/material/colors";
import Swal from "sweetalert2";
import Types from "Models/Types";
import { SectionModels } from "Models/SectionModels";
import { ChapterSectionTypes, Steps } from "Util/Const";
import { ChapterSectionTypeUrls, ChapterSectionStepTypeUrls } from "Routes/Urls";

export function openInNewTab(url) {
  window.open(url, "_blank", "noreferrer");
};

export function GetFirstDayOfYear(year) {
  return new Date(year, 0, 1);
}

export function GetLastDayOfYear(year) {
  return new Date(year, 11, 31);
}

export function GetFirstYear(fromAndToYear){
  const indexOfPipe = fromAndToYear.indexOf("|");

  return fromAndToYear.substring(0, indexOfPipe );
}

export function GetSecondYear(fromAndToYear){
  const indexOfPipe = fromAndToYear.indexOf("|");

  return fromAndToYear.substring( indexOfPipe+1 );
}

export function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export function UrlsAreEqual(urlPath1, urlPath2 ){
  const url1 = new URL( RemoveTrailingSlash(urlPath1), window.location.origin);
  const url2 = new URL( RemoveTrailingSlash(urlPath2), window.location.origin);

  if( url1.pathname === url2.pathname ){
    return true;
  } else {
    return false;
  }
}

export function GetDateOnly(){
  let date = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());

  return date;
}

export function AddDays(date, days) {
  let newDate = new Date(date.valueOf());
  newDate.setDate(date.getDate() + days);
  
  return newDate;
}

export function PopupConfirm(text, confirmCallback) {
  Swal.fire({
    customClass: "popupText",
    title: text,
    confirmButtonText: "OK",
    confirmButtonClass: "confirmButton",
    showCancelButton: true,
    cancelButtonText: "Avbryt",
    cancelButtonClass: "cancelButton",
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
}

export function PopupConfirmYesNo(text, confirmCallback) {
  Swal.fire({
    customClass: "popupText",
    title: text,
    confirmButtonText: "Ja",
    confirmButtonClass: "confirmButton",
    showCancelButton: true,
    cancelButtonText: "Nej",
    cancelButtonClass: "cancelButton",
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
}

export function PopupWithText(title, text) {
  Swal.fire({
    customClass: "popupText",
    title: title,
    text: text,
    confirmButtonText: "Stäng",
    confirmButtonClass: "confirmButton",
    buttonsStyling: false,
  });
}

export function PopupWithTextOK(title, text) {
  Swal.fire({
    customClass: "popupText",
    title: title,
    text: text,
    confirmButtonText: "OK",
    confirmButtonClass: "confirmButton",
    buttonsStyling: false,
  });
}

export function PopupWithCallBack(text, confirmCallback) {
  Swal.fire({
    customClass: "popupText",
    title: text,
    confirmButtonText: "OK",
    confirmButtonClass: "confirmButton",
    showCancelButton: false,
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
}

export function PopupGeneric(title, text, confirmCallback, confirmtext, showCancelButton, cancelText, cancelCallback) {
  Swal.fire({
    customClass: "popupText",
    title: title,
    text: text,
    confirmButtonText: confirmtext || "OK",
    confirmButtonClass: "confirmButton",
    showCancelButton: showCancelButton != null || cancelText != null || cancelCallback != null,
    cancelButtonText: cancelText || "Avbryt",
    cancelButtonClass: "cancelButton",
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    } else if (result.isDenied) {
      cancelCallback();
    }
  });
}

export function PopupError(text) {
  Swal.fire({
    customClass: "popupText",
    title: text,
    confirmButtonText: "OK",
    confirmButtonClass: "confirmButton",
    buttonsStyling: false,
  });
}

export function PopupErrorCallback(text, callback) {
  Swal.fire({
    customClass: "popupText",
    title: text,
    confirmButtonText: "OK",
    confirmButtonClass: "confirmButton",
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}

export const EmailValid = (email) => {
  if (typeof email !== "undefined") {
    // eslint-disable-next-line
    var pattern = new RegExp(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i    );
    if (pattern.test(email)) {
      return true;
    }
  }
  return false;
};

export const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

export async function getCurrentUserAndCompanyId() {
  let info = await Auth.currentUserInfo();
  return [info.username, info?.attributes["custom:customerId"]];
}

export async function getCurrentUserId() {
  let info = await Auth.currentUserInfo();
  return info.username;
}

export async function getCurrentCustomerId() {
  let info = await Auth.currentUserInfo();
  return info?.attributes["custom:customerId"];
}

export function clickAccordionButton(event, func) {
  event.stopPropagation();
  func();
}

export function GetDateString(dateText) {
  const dateObj = new Date(dateText);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();

  return year + "-" + padWithZeroes(month, 2) + "-" + padWithZeroes(date, 2);
}

export function padWithZeroes(number, length) {
  var str = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }

  return str;
}

// a little function to help us with reordering the result
export function reorderModel(list, startIndex, endIndex) {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.forEach((res, idx) => {
    console.log("res", res);
    res.order = idx;
  });

  return result;
}

// a little function to help us with reordering the result
export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.forEach((res, idx) => {
    res.order = idx;
  });

  return result;
}

export function IsStepDone(section, stepType) {
  const step = section?.Steps.items.find((step) => step.stepType === stepType);
  if (step != null) {
    return step?.done === true;
  }
  return false;
}

export function GetActiveSectionModels(sections) {
  const activeSections = Object.values(sections).filter((x) => x.active === true);

  const filteredSectionModels = Object.values(SectionModels)
                                .filter((x) => x.sectionType !== SectionModels.welcome.sectionType
                                                && x.sectionType !== SectionModels.introduction.sectionType
                                                && x.sectionType !== SectionModels.equalTreatmentPlan.sectionType);
  let activeSectionModels = [];

  for(var i=0, lenI = filteredSectionModels.length; i < lenI; i++){
    for(var j=0, lenJ = activeSections.length; j < lenJ; j++){
      if(filteredSectionModels[i].sectionType === activeSections[j].sectionType){
        activeSectionModels.push(filteredSectionModels[i]);
      }
    }
  }

  return activeSectionModels;
}

export function GetPlan(sections) {
  let activeSectionModels = GetActiveSectionModels(sections);

  let plan = [];
  for (let activeSectionModel of Object.values(activeSectionModels)) {
    
    let row = {
      sectionName: activeSectionModel.name,
      sectionType: activeSectionModel.sectionType,
      steps: [],
      done: false
    };
    
    for (let step of Object.values(Steps.chapters)) {
      const done =
        sections.find((x) => x.sectionType === activeSectionModel.sectionType).Steps.items.find((s) => s.stepType === step.value)?.done || false;
      row.steps.push({ stepName: step.displayValue, done });
    }

    //Check if all steps are true.
    let sectionDone = true;

    for (let i = 0; i < row.steps.length; i++) {
        if (row.steps[i].done === false) {
            sectionDone = false;
            break;
        }
    }

    row.done = sectionDone;
    plan.push(row);
  }

  return plan;
}

String.prototype.nthIndexOf = function(pattern, n) {
  var i = -1;

  while (n-- && i++ < this.length) {
      i = this.indexOf(pattern, i);
      if (i < 0) break;
  }

  return i;
}

export function GetBasePathWithParamNames(location, params){
  let basePathWithParamNames = location.pathname.toString();

  for(const key in params) {
    basePathWithParamNames = basePathWithParamNames.replace(params[key], ':' + key);
  }

  return basePathWithParamNames.toString();
};

export function RemoveTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}



export function GetChapterSectionTypeFromUrl(url){
  let chapterSection = null;

  for( const [index, currentChapterSection] of Object.values(ChapterSectionTypeUrls).entries() ){
    if( typeof currentChapterSection.subPath !== 'undefined' ) {
      if( url.includes(currentChapterSection.subPath) ){
        chapterSection = currentChapterSection; 
        break;
      }
    }
  }

  return chapterSection;
}

export function GetChapterSectionStepTypeFromUrl(url){
  let chapterSectionStep = null;

  for( const [index, currentChapterSectionStep] of Object.values(ChapterSectionStepTypeUrls).entries() ){
    if( typeof currentChapterSectionStep.subPath !== 'undefined' ) {
      if( url.includes(currentChapterSectionStep.subPath) ){
        chapterSectionStep = currentChapterSectionStep; 
        break;
      }
    }
  }

  return chapterSectionStep;
}

export function GetChapterSectionStepUrl( chapterSectionWithUrl, chapterSectionStepWithUrl, id = null){
  const idSubPath = id !== null ? "/" + id : "";

  const chapterSectionStepUrl = "/kapitel/" + chapterSectionWithUrl.subPath + "/" + chapterSectionStepWithUrl.subPath + idSubPath;

  return chapterSectionStepUrl;
}

export function FindPageObjectFromUrl(urls, url) {
  url = RemoveTrailingSlash(url);
  let pageObject = null;

  if(urls){
    for (const [index, currentPageObject] of Object.values(urls).entries()){
      if (typeof currentPageObject.path !== 'undefined') {
        if (currentPageObject.path === url) {
          pageObject = currentPageObject;
          break;
        }
      }
    
      //Fix :, for example :id URL check.
      if (pageObject === null) {
        let tempPageObject = FindPageObjectFromUrl(currentPageObject.subPages, url);

        if (tempPageObject != null) {
            pageObject = tempPageObject;
        }
      } else {
        break;
      }
    };
  }

  return pageObject;
}

export function FindPageObjectFromChapterSectionType(urls, chapterSectionType) {
  let pageObject = null;

  if(urls){
    for (const [index, currentPageObject] of Object.values(urls).entries()){
      if (typeof currentPageObject.chapterSectionType !== 'undefined') {
        if( currentPageObject.chapterSectionType === chapterSectionType) {
          pageObject = currentPageObject;
          break;
        }
      }

      if (pageObject === null) {
        let tempPageObject = FindPageObjectFromChapterSectionType(currentPageObject.subPages, chapterSectionType);

        if (tempPageObject != null) {
          pageObject = tempPageObject;
        }
      } else {
        break;
      }
    };
  }

  return pageObject;
}

export async function getAllRiskAnalisysAndActionsForRiskAnalisysAndActionYear( riskAnalisysAndActionYear ){
  //Get all riskAnalisysAndActionItems for workingConditions.
  const riskAnalisysAndActions = [];

  riskAnalisysAndActions.push(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.workingConditions.Name]);
  riskAnalisysAndActions.push(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.employmentAndParenthood.Name]);
  riskAnalisysAndActions.push(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.wagesAndEmploymentTerms.Name]);
  riskAnalisysAndActions.push(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.recruitmentAndPromotion.Name]);
  riskAnalisysAndActions.push(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.educationAndSkillsDevelopment.Name]);
  riskAnalisysAndActions.push(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.measuresToPreventHarassment.Name]);
  riskAnalisysAndActions.push(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.equalGenderDistribution.Name]);

  return riskAnalisysAndActions;
}