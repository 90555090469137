import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useForceUpdate from 'use-force-update';
import { ChapterSectionStepTypeUrls } from "Routes/Urls";
import Loading from "Components/Loading";
import ChapterSectionStepBar from "Components/Steps/ChapterSectionStepBar";
import { GetChapterSectionStepUrl } from "Util/Utils";
import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow, Grid, Paper, FormControl } from "@mui/material";
import { ExpandMore, CheckBoxOutlineBlank, CheckBox, CheckBoxOutlined } from "@mui/icons-material";
import {
  useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation
} from 'API/UserDataStorageAPI';
import {
  getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear,
  updateRisksDefinedAndAnalyzedInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear,
  updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear,
  deleteRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear
} from 'API/SelectOrModifyUserDataStorageItems';
import { MultiLineTextFieldWithPlaceholder, TwKey, EditButton, DeleteButton, CheckBoxToggleButton, PopupConfirmYesNoWithValue } from "Components/StandardTextComponentsWithTranslation";
import { setAppStateSetting } from 'Features/AppState/AppStateSlice';

const Summery = () => {
  const navigate = useNavigate();

  const appState = useSelector((state) => state.settings.appState);
  const dispatch = useDispatch();

  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery();

  const [
    updateRiskAnalisysAndActionYearsForLoggedInCustomer, // This is the mutation trigger
    { isLoading: isUpdatingUserDataStorageForLoggedInCustomer }, // This is the destructured mutation result
  ] = useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation();


  const forceUpdate = useForceUpdate();

  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateOpenAccordions, setStateOpenAccordions] = useState([false]);

  useEffect(() => {
    async function asyncUseEffect() {
      const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();

      const selectedRiskAnalisysAndActionLazy = await getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYearsLazy, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );

      //Add true for the first accordion and false for the rest.
      if ( selectedRiskAnalisysAndActionLazy.riskAnalisysAndActionItems.length > 0 ){
        const openAccordions = [false];

        for(let i = 1; i < selectedRiskAnalisysAndActionLazy.riskAnalisysAndActionItems.length; i++){
          openAccordions.push(false);
        }

        setStateOpenAccordions( openAccordions );
      }

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
      
      //Force a rerender.
      forceUpdate();
    }

    asyncUseEffect();
  }, []);

  async function toggleOpenAccordion(id){
    //Get the array of accordions.
    const openAccordions = stateOpenAccordions;

    console.log("toggleOpenAccordion - openAccordions before change", openAccordions);

    //Toggle the one with the id.
    openAccordions[id] = !openAccordions[id];

    console.log("toggleOpenAccordion - openAccordions after change", openAccordions);

    //Set the state.
    setStateOpenAccordions( openAccordions );

    //Force a rerender.
    forceUpdate();
  }

  async function updateRisksDefinedAndAnalized( value ){
    //Update the value.
    const riskAnalisysAndActionYearsToUpdate = await updateRisksDefinedAndAnalyzedInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, value);
    
    //Update database.
    var result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

    //Trigger rerender of side menu.
    dispatch( setAppStateSetting( { rerenderSideMenu: true } ));

    //Force a rerender.
    forceUpdate();
  }

  async function deleteRiskAnalisysAndActionItemButtonOnClick( riskAnalisysAndActionItemToDeleteId ){
    //Update the value.
    const riskAnalisysAndActionYearsToUpdate = await deleteRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, riskAnalisysAndActionItemToDeleteId);

    //Update database.
    var result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

    //Force a rerender.
    forceUpdate();
  }

  if ( stateIsLoading || riskAnalisysAndActionYearsIsLoading ) {
    return <Loading />;
  } else {
    const selectedRiskAnalisysAndAction = getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );

    return (
      <>
        <div className="innerContentWrapper">
          <ChapterSectionStepBar chapterSectionStepBarClickable={ true } />
          <div className="outerContainerForAccordion">
            <div className="chapterStep">
              <div className="chapterStepTitle">
                <div className="accordionSummary">
                  <h2><TwKey textKey={ "10011" } /></h2>
                  <div>
                      <CheckBoxToggleButton 
                          currentStateIsTrue={ selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed }
                          buttonTextKeyIfTrue="10086"
                          toolTipTitleTextKeyIfTrue="10384"
                          onClickIfTrue={ async () => await updateRisksDefinedAndAnalized( false ) }
                          buttonTextKeyIfFalse="10087"
                          toolTipTitleTextKeyIfFalse="10385"
                          onClickIfFalse={ async () => await updateRisksDefinedAndAnalized( true ) }
                    ></CheckBoxToggleButton>
                    
                  </div>
                </div>
              </div>
              <div className="chapterStepContent">
                <div style={{ width: "100%" }}>
                  <Grid container spacing={0} className="basicPaper" style={{ width: "calc(100% - 35px)", marginBottom: "20px" }}>
                    <Grid container>
                      <Grid item xs={4} sm={4}>
                        <Paper elevation={0} className="basicPaper">
                          <b><TwKey textKey={ "10026" } /></b>
                        </Paper>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Paper elevation={0} className="basicPaper">
                          <b><TwKey textKey={ "10027" } /></b>
                        </Paper>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Paper elevation={0} style={{minWidth: "150px"}} className="basicPaper">
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={{width: "100%"}}>
                    {
                      selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.map(( riskAnalisysAndActionItem, idx ) => (
                        <Accordion
                          key={idx}
                          elevation={0}
                          className={ idx % 2 == 0 ? "backGroundColor5" : "accordionEveryOther" }
                          // className="backGroundColor5"
                          expanded={ stateOpenAccordions[idx]}
                        >
                          <AccordionSummary
                            className="accordionSummary"
                            style={{ padding: "0px" }}
                            expandIcon={
                              <ExpandMore
                                style={{cursor: "pointer" }}
                                onClick={ async () => await toggleOpenAccordion(idx)}
                              />
                            }
                            sx={{ cursor: 'unset !important' }}
                          >
                            <div className="accordionSummary" style={{paddingRight: "3px"}}>
                              <Grid container spacing={0} className="">
                                <Grid container>
                                  <Grid item xs={4} sm={4} className="accordionSummaryRiskRow">
                                    <Paper
                                      elevation={0}
                                      className={ idx % 2 == 0 ? "basicPaper" : "accordionEveryOther" }
                                    >
                                      {riskAnalisysAndActionItem?.risk}
                                    </Paper>
                                  </Grid>
                                  <Grid item xs={4} sm={4} className="accordionSummaryRiskRow">
                                    <Paper
                                      elevation={0}
                                      className={ idx % 2 == 0 ? "basicPaper" : "accordionEveryOther" }
                                    >
                                      {riskAnalisysAndActionItem?.consequence}
                                    </Paper>
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    <Paper
                                      elevation={0}
                                      style={{minWidth: "150px", textAlign: "Right"}}
                                      className={ idx % 2 == 0 ? "basicPaper" : "accordionEveryOther" }
                                    >
                                      <FormControl style={{paddingRight: "3px"}}>
                                        <EditButton
                                          buttonTextKey="10101"
                                          toolTipTitleTextKey="10102"
                                          onClick={ () => navigate(GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.risks, riskAnalisysAndActionItem.id ))}
                                          disabled={selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <DeleteButton
                                          buttonTextKey = "10103"
                                          toolTipTitleTextKey = "10104"
                                          onClick={ () => PopupConfirmYesNoWithValue( "10127", riskAnalisysAndActionItem.risk, async () => await deleteRiskAnalisysAndActionItemButtonOnClick(riskAnalisysAndActionItem.id))}
                                          disabled={selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                        />                                        
                                      </FormControl>
                                    </Paper>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails style={{padding: "0", marginLeft: "50px", marginRight: "50px" }}>
                            <div style={{width: "100%" }}>
                              <h4><TwKey textKey={ "10033" } /></h4>
                              <div style={{ width: "100%" }} className="textInputBox">
                                <MultiLineTextFieldWithPlaceholder
                                  placeholderTextKey="10126"
                                  value={riskAnalisysAndActionItem.analisys}
                                  minRows={5}
                                  maxRows={10}
                                  disabled={true}
                                />
                              </div>
                              <br></br>
                              <div>
                              
                              </div>
                              {
                                Object.values(riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems).length > 0 ?
                                  <Table>
                                    <TableHead style={{ backgroundColor: "#FFFFFF" }}>
                                      <TableRow  style={{ backgroundColor: "#FFFFFF" }}>
                                        <TableCell  style={{ backgroundColor: "#FFFFFF" }}>
                                          <b><TwKey textKey={ "10029" } /></b>
                                        </TableCell>
                                        <TableCell>
                                          <b><TwKey textKey={ "10030" } /></b>
                                        </TableCell>
                                        <TableCell>
                                          <b><TwKey textKey={ "10031" } /></b>
                                        </TableCell>
                                        <TableCell>
                                          <b><TwKey textKey={ "10032" } /></b>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {
                                        riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.map(( riskAnalisysAndActionTaskItem, idx ) => (
                                          <TableRow key={idx} style={{verticalAlign: "Middle"}}>
                                            <TableCell>
                                              { riskAnalisysAndActionTaskItem.task }
                                            </TableCell>
                                            <TableCell>
                                              { riskAnalisysAndActionTaskItem.responsible }
                                            </TableCell>
                                            <TableCell>
                                              {
                                                riskAnalisysAndActionTaskItem.ongoing ?
                                                  <CheckBoxOutlined className="checkBoxColorDisabled" />
                                                :
                                                  <CheckBoxOutlineBlank className="checkBoxColorDisabled" />
                                              }
                                            </TableCell>
                                            <TableCell>
                                              {
                                                riskAnalisysAndActionTaskItem.ongoing ?
                                                  ""
                                                :
                                                  riskAnalisysAndActionTaskItem.followUpDate
                                              }
                                            </TableCell>
                                          </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                :
                                  riskAnalisysAndActionItem.remedy ? 
                                    <TwKey textKey="10269"/>
                                  :
                                    <TwKey textKey="10270"/>
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Summery;