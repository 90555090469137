import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from "@mui/material";
import { listMaterialss } from "graphql/queries";
import { GetAllItems } from "Util/Api";
import { NewDownloadFunction } from "Util/StorageManager";
import { TwKey} from "Components/StandardTextComponentsWithTranslation";
import { AuthenticatedUrls } from 'Routes/Urls';
import Loading from "Components/Loading";
import Updating from "Components/Updating";

const Material = () => {

  const appState = useSelector((state) => state.settings.appState);
  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateIsUpdating, setStateIsUpdating] = useState( false );
  const [stateMaterials, setStateMaterials] = useState( [] ); 

  useEffect(() => {
    async function asyncUseEffect(){
      await getData();

      //Scroll to the top of the page.
      window.scrollTo(0, 0);
    }

    asyncUseEffect();
  }, []);

  async function getData() {
    const files = await GetAllItems(listMaterialss);
    
    setStateIsLoading( false );
    setStateMaterials( files );
  }

  async function downloadFile(url, name){
    console.log("Got here!");
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  }

  if ( stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <>
        { stateIsUpdating && <Updating /> }
        <div className="innerContentWrapper">
          <div className="introduction">
            <div className="introductionTitle">
              <h2><TwKey textKey="10282"/></h2>
            </div>
            <div className="introductionContent">
              <Table style={{ minWidth: "30%", maxWidth: "100%", textAlign: "left" }} cellSpacing="0" cellPadding="0">
                <TableHead>
                  <TableRow>
                    <TableCell><TwKey textKey="10286"/></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { stateMaterials
                        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                        .map((material, index) => (
                    <TableRow key={index}>
                      <TableCell>
                      {
                        //Check if file or link:
                        material.type == 0 ?
                          <a href={ AuthenticatedUrls.content.subPages.materials.path + "/" + material.name } onClick={ async () => await NewDownloadFunction( material.path, material.name ) } target="_blank" rel="noreferrer">{ material.name }</a>
                        :
                          <a href={ material.path } target="_blank" rel="noreferrer">{ material.name }</a>
                      }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </div>
            </div>
          </div>
      </>
    );
  }
}

export default Material;
