import { API } from "aws-amplify";
import { getCurrentUserId, getCurrentCustomerId } from "Util/Utils";
import { v4 as uuidv4 } from 'uuid';
import { getUserDataStorage } from "graphql/queries";
import { createUserDataStorage, updateUserDataStorage } from "graphql/mutations";
import { RiskAnalisysAndActionYear } from 'Classes/UserData';
import { GetFirstDayOfYear, GetLastDayOfYear } from "Util/Utils";

export async function SaveNewItem(item, dbCreate, authMode) {
  //Default as cognito user mode
  authMode = authMode || "AMAZON_COGNITO_USER_POOLS";
  const userId = await getCurrentUserId();
  let res = null;

  try {
    //Save new item
    let dataToSave = { input: { ...item, createdById: userId, changedById: userId }};

    res = await API.graphql({ query: dbCreate, variables: dataToSave, authMode });

    res = Object.values(res.data)[0];
  } catch (err) {
    console.error("Cound not save ", item, " error", err);
  }
  return res;
}

//Update DB only, do not return data from DB.
export async function Update(item, dbUpdate, authMode) {
  //Default as cognito user mode
  authMode = authMode || "AMAZON_COGNITO_USER_POOLS";
  const userId = await getCurrentUserId();
  let res = null;
  try {
    //Updaste
    let dataToUpdate = { input: { ...item, changedById: userId } };

    res = await API.graphql({ query: dbUpdate, variables: dataToUpdate, authMode } );
    
    res = Object.values(res.data)[0];
  } catch (err) {
    console.error("Cound not update", item, " error", err);
  }
  return res;
}

export async function SaveOrUpdate(item, dbUpdate, dbCreate, authMode) {
  console.log("item", item);
  
  //Default as cognito user mode
  authMode = authMode || "AMAZON_COGNITO_USER_POOLS";
  const userId = await getCurrentUserId();
  let res = null;
  try {
    if (item.id != null) {
      //uppdatera
      let dataToUpdate = { input: { ...item, changedById: userId } };

      res = await API.graphql({ query: dbUpdate, variables: dataToUpdate, authMode } );
    } else {
      //Spara
      let dataToSave = { input: { ...item, createdById: userId, changedById: userId }};

      res = await API.graphql({ query: dbCreate, variables: dataToSave, authMode });
    }
    res = Object.values(res.data)[0];
  } catch (err) {
    console.error("Cound not save or update", item, " error", err);
  }
  return res;
}

export async function DeleteItemById(id, dbDelete, authMode) {
  //Default as cognito user mode
  authMode = authMode || "AMAZON_COGNITO_USER_POOLS";
  try {
    await API.graphql({ query: dbDelete, variables: { input: { id } }, authMode });

    return true;
  } catch (err) {
    console.error("Cound not delete by id ", id, " error", err);
    return false;
  }
}

//Use this function with extreme caution!
//There are very few instances when you want to get all items of a model!
export async function GetAllItems(dbList, authMode) {
  //Get all items
  let orgItems = (await API.graphql({ query: dbList, authMode }))?.data;
  let items = Object.values(orgItems)[0].items;

  return items;
}

export async function GetItemById(id, dbGet, authMode) {
  //Default as cognito user mode
  authMode = authMode || "AMAZON_COGNITO_USER_POOLS";
  try {
    let item = (await API.graphql({ query: dbGet, variables: { id: id }, authMode }))?.data;
    return Object.values(item)[0];
  } catch (err) {
    console.error("Cound not get by id ", id, " error", err);
    return null;
  }
}

async function getNewRiskAnalisysAndActionYears(){
  var newRiskAnalisysAndActionYear = new RiskAnalisysAndActionYear(uuidv4());

  const userId = await getCurrentUserId();

  newRiskAnalisysAndActionYear.active = true;
  newRiskAnalisysAndActionYear.fromDate = GetFirstDayOfYear(new Date().getFullYear());
  newRiskAnalisysAndActionYear.toDate = GetLastDayOfYear(new Date().getFullYear());
  newRiskAnalisysAndActionYear.createdBy = userId;
  newRiskAnalisysAndActionYear.changedBy = userId;

  var riskAnalisysAndActionYearsToBeCreated = [];

  riskAnalisysAndActionYearsToBeCreated.push(newRiskAnalisysAndActionYear);

  return riskAnalisysAndActionYearsToBeCreated;
}

export async function GetOrCreateUserDataStorageItemForLoggedInCustomerAndReturnTheItem(){
  let itemFromDB = null;
  let item = null;

  //Get the current customer Id.
  const customerId = await getCurrentCustomerId();

  try{
    //Try and get the Item from DB if we have an ID to search for.
    if( customerId !== null && customerId !== undefined){
      itemFromDB = await GetItemById(customerId, getUserDataStorage);
    }

    //Check if we got exactly 1 item back. Else there is something wrong since we tried to get Item by ID.
    //Check if the item exist and if so get it, else create it and then get it.
    if( itemFromDB && itemFromDB.length === 1) {
      //The item exists and the return type is an array with just one object.

      item = JSON.parse(itemFromDB[0].data);
    } else if( itemFromDB && itemFromDB.length === undefined ){
      //The item exists and it is just an object (that is not in an array).
      item = JSON.parse(itemFromDB.data);
    } else if( itemFromDB !== null && itemFromDB.length > 1){
      throw new Error("Invalid use if this function! Filtered items should only return 1 value!");
    }
    
    if ( !item ){
      const riskAnalisysAndActionYearsToBeCreated = getNewRiskAnalisysAndActionYears();

      const riskAnalisysAndActionYearsAsJSON = JSON.stringify(riskAnalisysAndActionYearsToBeCreated);

      const userId = await getCurrentUserId();

      const riskAnalisysAndActionYears = { id: customerId, data: riskAnalisysAndActionYearsAsJSON, createdById: userId, changedById: userId };

      let res = await SaveNewItem(riskAnalisysAndActionYears, createUserDataStorage);

      item = riskAnalisysAndActionYearsToBeCreated;

      //Check if we got a result.
      if( res === null){
        throw new Error("Could not get or create item from db");
      }
    } else if ( Object.keys(item).length === 0 && item.constructor === Object ) {
      const riskAnalisysAndActionYearsToBeCreated = getNewRiskAnalisysAndActionYears();

      const riskAnalisysAndActionYearsAsJSON = JSON.stringify(riskAnalisysAndActionYearsToBeCreated);

      const userId = await getCurrentUserId();

      const riskAnalisysAndActionYears = { id: customerId, data: riskAnalisysAndActionYearsAsJSON, createdById: itemFromDB.createdById, changedById: userId };

      let res = await Update(riskAnalisysAndActionYears, updateUserDataStorage);

      item = riskAnalisysAndActionYearsToBeCreated;

      //Check if we got a result.
      if( res === null){
        throw new Error("Could not get or create item from db");
      }
    }
  } catch (err) {
    console.error(err);
  }

  return item;
}

//Update DB only, do not return data from DB.
export async function UpdateUserDataStorageItem(userDataStorageItem) { 
  //Default as cognito user mode
  const authMode = "AMAZON_COGNITO_USER_POOLS";
  const userId = await getCurrentUserId();

  const customerId = await getCurrentCustomerId();

  var userDataStorageItemToUpdate = null;

  let res = null;
  try {
    //Update
    const userDataStorageItemAsJSON = JSON.stringify(userDataStorageItem);

    userDataStorageItemToUpdate = { input: { id: customerId, data: userDataStorageItemAsJSON, div: 0, createdById: userId, changedById: userId } };

    const result = await API.graphql({ query: updateUserDataStorage, variables: userDataStorageItemToUpdate, authMode } );
    
    res = Object.values(result.data)[0];
  } catch (err) {
    console.error("Cound not update", userDataStorageItemToUpdate, " error", err);
  }

  return res;
}


export async function GetOrCreateAndGetItem(itemToGetOrCreate, getQuery, createQuerry){
  let itemFromDB = null;
  let item = null;

  try{
    //Try and get the Item from DB if we have an ID to search for.
    if( itemToGetOrCreate.id !== null && itemToGetOrCreate.id !== undefined){
      itemFromDB = await GetItemById(itemToGetOrCreate.id, getQuery);
    }

    //Check if we got exactly 1 item back. Else there is something wrong since
    //Check if the item exist and if so get it, else create it and then get it.
    if( itemFromDB !== null && itemFromDB.length === 1){
      //The item exists. Get the latest version from DB.
      item = itemFromDB[0];
    } else if( itemFromDB !== null && itemFromDB.length > 1){
      throw new Error("Invalid use if this function! Filtered items should only return 1 value!");
    } else {
      //This item does not exist. Create it.
      let res = await SaveOrUpdate(itemToGetOrCreate, null, createQuerry);

      //Check if we got a result.
      if( res === null){
        throw new Error("Could not get or create item from db");
      } else {
        //We were able to save the the data to the DB. Retrieve the item.
        item = await GetItemById(res.id, getQuery);
      }
    }
  } catch (err) {
    console.error(err);
  }

  return item;
}

//Get one item if such exists, otherwise create and then get.
export async function CreateAndGetItemCheckFilter(items, filterProperty, value, getQuery, createQuerry, newItem) {
  let filteredItems = null;
  let item = null;
  
  try{
    //Check if we have any items.
    if( items !== null && items.length > 0)
    {
      //Filter out the item we want to get.
      filteredItems = items.filter((filterItem) => filterItem[filterProperty] === value);
    } 

    //Check if the item exist and if so get it, else create it and then get it.
    if( filteredItems !== null && filteredItems.length === 1){
      //The item exists. Get the latest version from DB.
      item = await GetItemById(filteredItems[0].id, getQuery);
    } else if( filteredItems !== null && filteredItems.length > 1){
      throw new Error("Invalid use if this function! Filtered items should only return 1 value!");
    } else {
      //This item does not exist. Create it.
      let res = await SaveOrUpdate(newItem, null, createQuerry);

      //Check if we got a result.
      if( res === null){
        throw new Error("Could not get or create item from db");
      } else {
        //We were able to save the the data to the DB. Retrieve the item.
        item = await GetItemById(res.id, getQuery);
      }
    }
  } catch (err) {
    console.error(err);
  }

  return item;
}