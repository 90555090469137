/*
    Note:
    It is very important in Javascript to set an initial value since it will determine the type of the variable.

    Examples:
    * Strings: Set to "".
    * Date: Set to new Date()

    Only set "null" if "null" is a valid value, for instance if you need to store an object.
*/

import { v4 as uuidv4 } from 'uuid';
import { ChapterSectionTypes } from "Util/Const";

export class RiskAnalisysAndActionTaskItemToUpdate {
    id;
    riskAnalisysAndActionId;
    riskAnalisysAndActionItemId;
    task;
    responsible;
    ongoing;
    followUpDate;
    fixedDate;
    comment;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor( id ) {        
        this.id = id;
        this.riskAnalisysAndActionId = null;
        this.riskAnalisysAndActionItemId = null;
        this.task = "";
        this.responsible = "";
        this.ongoing = false;
        this.followUpDate = null;
        this.fixedDate = null;
        this.comment = "";
        this.createdBy = "";
        this.createdAt = new Date();
        this.changedBy = "";
        this.changedAt = new Date();
    }
}

export class RiskAnalisysAndActionTaskItem {
    id;
    riskAnalisysAndActionItemId;
    task;
    responsible;
    ongoing;
    followUpDate;
    fixed;
    fixedDate;
    comment;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor(id) {
        this.id = id;
        this.riskAnalisysAndActionItemId = null;
        this.task = "";
        this.responsible = "";
        this.ongoing = false;
        this.followUpDate = null;
        this.fixed = false;
        this.fixedDate = null;
        this.comment = "";
        this.createdBy = "";
        this.createdAt = new Date();
        this.changedBy = "";
        this.changedAt = new Date();
    }
}

export class PreparationItem {
    id;
    name;
    checked;
    order;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor(id, name, checked, order) {
        this.id = id;
        this.name = name;
        this.checked = checked;
        this.order = order;
        this.createdBy = "";
        this.createdAt = new Date();
        this.changedBy = "";
        this.changedAt = new Date();
    }
}

export class RiskAnalisysAndActionItem {
    id;
    riskAnalisysAndActionId;
    risk;
    consequence;
    analisys;
    remedy;
    riskAnalisysAndActionTaskItems;
    followUpText;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor(id) {
        this.id = id;
        this.riskAnalisysAndActionId = null;
        this.risk = "";
        this.consequence = "";
        this.analisys = "";
        this.remedy = true;
        this.riskAnalisysAndActionTaskItems = [];
        this.followUpText = "";
        this.createdBy = "";
        this.createdAt = new Date();
        this.changedBy = "";
        this.changedAt = new Date();
    }
}

export class RiskAnalisysAndAction {
    id;
    chapterSectionTypeName;
    titleTextKey;
    informationTextKey;
    chapterDescriptionTextKey;
    tasksChapterInformationTextKey;
    statusChapterInformationTextKey;
    riskAnalisysAndActionItems;
    risksDefinedAndAnalyzed;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor(id, chapterSectionTypeName, titleTextKey, chapterDescriptionTextKey, chapterInformationTextKey, tasksChapterInformationTextKey, statusChapterInformationTextKey) {
        this.id = id;
        this.chapterSectionTypeName = chapterSectionTypeName;
        this.titleTextKey = titleTextKey;
        this.chapterDescriptionTextKey = chapterDescriptionTextKey;
        this.chapterInformationTextKey = chapterInformationTextKey;
        this.tasksChapterInformationTextKey = tasksChapterInformationTextKey;
        this.statusChapterInformationTextKey = statusChapterInformationTextKey;
        this.riskAnalisysAndActionItems = [];
        this.risksDefinedAndAnalyzed = false;
        this.createdBy = "";
        this.createdAt = new Date();
        this.changedBy = "";
        this.changedAt = new Date();
    }
}

export class RiskAnalisysAndActionYear {
    id;
    fromDate;
    toDate;
    authors;
    preparations;
    riskAnalisysAndActions;
    active;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor (id) {
        this.id = id;
        this.fromDate = new Date();
        this.toDate = new Date();
        this.active = false;
        this.authors = "";
        this.preparations = [];
        this.riskAnalisysAndActions = {
            workingConditions: new RiskAnalisysAndAction(uuidv4(), ChapterSectionTypes.workingConditions.Name, "10150", "10164","10216","10233","10251"),
            employmentAndParenthood: new RiskAnalisysAndAction(uuidv4(), ChapterSectionTypes.employmentAndParenthood.Name, "10154","10203","10220","10237","10255"),
            wagesAndEmploymentTerms: new RiskAnalisysAndAction(uuidv4(), ChapterSectionTypes.wagesAndEmploymentTerms.Name, "10151","10200","10217","10234","10252"),
            recruitmentAndPromotion: new RiskAnalisysAndAction(uuidv4(), ChapterSectionTypes.recruitmentAndPromotion.Name, "10152","10201","10218","10235","10253"),
            educationAndSkillsDevelopment: new RiskAnalisysAndAction(uuidv4(), ChapterSectionTypes.educationAndSkillsDevelopment.Name, "10153","10202","10219","10236","10254"),
            measuresToPreventHarassment: new RiskAnalisysAndAction(uuidv4(), ChapterSectionTypes.measuresToPreventHarassment.Name, "10155","10204","10221","10238","10256"),
            equalGenderDistribution: new RiskAnalisysAndAction(uuidv4(), ChapterSectionTypes.equalGenderDistribution.Name, "10156","10205","10222","10239","10257"),
        };
        this.createdBy = "";
        this.createdAt = new Date();
        this.changedBy = "";
        this.changedAt = new Date();
    }
}