import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loading from "Components/Loading";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Check } from "@mui/icons-material";
import { TwKey, InfoText } from "Components/StandardTextComponentsWithTranslation";
import {
  useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
} from 'API/UserDataStorageAPI';
import {
  getActiveRiskAnalisysAndActionYear
} from 'API/SelectOrModifyUserDataStorageItems';
import { getAllRiskAnalisysAndActionsForRiskAnalisysAndActionYear } from "Util/Utils";

class EqualTreatmentPlanStatusObject  {
  titleTextKey;
  statusChapterInformationTextKey;
  riskAnalisysAndActionStatus;
  risksDefinedAndAnalyzed;
  allRiskAnalisysAndActionTaskItemsFixed;
  numberOfTasksDone;
  totalNumberOfTasks;
  constructor( titleTextKey, statusChapterInformationTextKey ){
    this.titleTextKey = titleTextKey;
    this.statusChapterInformationTextKey = statusChapterInformationTextKey;
    this.riskAnalisysAndActionStatus = false;
    this.risksDefinedAndAnalyzed = false;
    this.numberOfTasksDone = 0;
    this.totalNumberOfTasks = 0;
    this.allRiskAnalisysAndActionTaskItemsFixed  = false;
  }
}

const EqualTreatmentPlanStatus = () => {
  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateRiskAnalisysAndActionStatuses, setStateRiskAnalisysAndActionStatuses] = useState([]);

  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery();

  useEffect(() => {
    async function asyncUseEffect() {
      setStateRiskAnalisysAndActionStatuses ( await getRiskAnalisysAndActionStatuses() );

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, []);

  //Only run if riskAnalisysAndActionYears is changed.
  useEffect(() => {
    async function asyncUseEffect() {
      setStateRiskAnalisysAndActionStatuses ( await getRiskAnalisysAndActionStatuses() );

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, [riskAnalisysAndActionYears]);

  async function getRiskAnalisysAndActionStatuses() {
    var localRiskAnalisysAndActionYears = null;

    if( riskAnalisysAndActionYears ){
      localRiskAnalisysAndActionYears = riskAnalisysAndActionYears;
    } else {
      localRiskAnalisysAndActionYears = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();
    }

    const selectedRiskAnalisysAndActionYear = await getActiveRiskAnalisysAndActionYear( localRiskAnalisysAndActionYears );

    const allRiskAnalisysAndActions = await getAllRiskAnalisysAndActionsForRiskAnalisysAndActionYear( selectedRiskAnalisysAndActionYear );
    const equalTreatmentPlanStatusObjects = [];

    for (const riskAnalisysAndAction of allRiskAnalisysAndActions){
      const equalTreatmentPlanStatusObject = new EqualTreatmentPlanStatusObject(riskAnalisysAndAction.titleTextKey, riskAnalisysAndAction.statusChapterInformationTextKey);

      //Set this to true if all risks are defined.
      equalTreatmentPlanStatusObject.risksDefinedAndAnalyzed = riskAnalisysAndAction.risksDefinedAndAnalyzed;

      if( equalTreatmentPlanStatusObject.risksDefinedAndAnalyzed === true ){
        //We assume that all items are fixed.
        equalTreatmentPlanStatusObject.allRiskAnalisysAndActionTaskItemsFixed = true;
        
        for (const riskAnalisysAndActionItem of riskAnalisysAndAction.riskAnalisysAndActionItems){
          for (const riskAnalisysAndActionTaskItem of riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems){
            //Check if one of the items are false, and if so, set the all to false.
            if ( riskAnalisysAndActionTaskItem.fixed === true ){
              equalTreatmentPlanStatusObject.numberOfTasksDone += 1;
            } else {
              equalTreatmentPlanStatusObject.allRiskAnalisysAndActionTaskItemsFixed = false;
            }

            //increase the total number of tasks.
            equalTreatmentPlanStatusObject.totalNumberOfTasks += 1;
          }
        }
      }

      //Set to true if both of them are true.
      if ( equalTreatmentPlanStatusObject.risksDefinedAndAnalyzed === true && equalTreatmentPlanStatusObject.allRiskAnalisysAndActionTaskItemsFixed === true ){
        equalTreatmentPlanStatusObject.riskAnalisysAndActionStatus = true;
      }

      equalTreatmentPlanStatusObjects.push(equalTreatmentPlanStatusObject);
    }

    return equalTreatmentPlanStatusObjects;
  }
    
  if (stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="sectionTopPadding"></div>
        <div className="outerContainerForAccordion">
          <div className="chapterStep">
            {/* <div className="chapterStepTitle">
              <h2><TwKey textKey="10160" />&nbsp;<InfoText textKey="10247" /></h2>
            </div> */}
            <div className="chapterStepContent">
            <Table className="planTable" cellSpacing="10">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b><TwKey textKey="10161" />&nbsp;<InfoText textKey="10248" /></b>
                    </TableCell>
                  <TableCell>
                    <b><TwKey textKey="10162" />&nbsp;<InfoText textKey="10249" /></b>
                  </TableCell>
                  <TableCell>
                    <b><TwKey textKey="10163" />&nbsp;<InfoText textKey="10250" /></b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(stateRiskAnalisysAndActionStatuses).map((stateRiskAnalisysAndActionStatus, index) => (
                  <TableRow key={index}>
                    <TableCell
                        className={ stateRiskAnalisysAndActionStatus.riskAnalisysAndActionStatus ? "background-ready" : "background-not-ready" }
                        style={{ width: "33%"}}
                      >
                      <span className={ stateRiskAnalisysAndActionStatus.riskAnalisysAndActionStatus ? "background-ready" : "background-not-ready" }>
                        <TwKey textKey={stateRiskAnalisysAndActionStatus.titleTextKey}/>&nbsp;<InfoText textKey={stateRiskAnalisysAndActionStatus.statusChapterInformationTextKey} />
                      </span>
                    </TableCell>
                    <TableCell
                        className={stateRiskAnalisysAndActionStatus.risksDefinedAndAnalyzed ? "background-ready" : " background-not-ready"}
                        style={{ textAlign: "center", width: "33%"}}
                      >
                      <span className={stateRiskAnalisysAndActionStatus.risksDefinedAndAnalyzed ? "background-ready" : " background-not-ready"} >
                        {stateRiskAnalisysAndActionStatus.risksDefinedAndAnalyzed ? <Check /> : "" }
                      </span>
                    </TableCell>
                    <TableCell
                      className={stateRiskAnalisysAndActionStatus.allRiskAnalisysAndActionTaskItemsFixed ? "background-ready" : " background-not-ready"}
                      style={{ textAlign: "center", width: "33%"}}
                    >
                      <span className={stateRiskAnalisysAndActionStatus.allRiskAnalisysAndActionTaskItemsFixed ? "background-ready" : " background-not-ready"} >
                        { stateRiskAnalisysAndActionStatus.allRiskAnalisysAndActionTaskItemsFixed ?
                            <Check />
                          :
                            stateRiskAnalisysAndActionStatus.numberOfTasksDone + " / " + stateRiskAnalisysAndActionStatus.totalNumberOfTasks
                        }
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <br />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EqualTreatmentPlanStatus;