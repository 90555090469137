import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useForceUpdate from 'use-force-update';
import Loading from "Components/Loading";
import { Table, TableHead, TableBody, TableRow, TableCell, Accordion, AccordionDetails, AccordionSummary, IconButton, TextField, FormControl } from "@mui/material";
import { ExpandMore, CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { TextFieldWithPlaceholder, MultiLineTextFieldWithPlaceholder, TwKey, EditButton, SaveButton, CancelButton, InfoText } from "Components/StandardTextComponentsWithTranslation";
import { RiskAnalisysAndActionTaskItem } from "Classes/UserData";
import { getAllRiskAnalisysAndActionsForRiskAnalisysAndActionYear } from "Util/Utils";
import { Autosave } from 'react-autosave';
import {
  useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation
} from 'API/UserDataStorageAPI';
import {
  getActiveRiskAnalisysAndActionYear,
  updateActiveRiskAnalisysAndActionYear,
  updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear
} from 'API/SelectOrModifyUserDataStorageItems';
import "SuperResponsiveTableStyle.css"

const RiskActions = () => {
  const forceUpdate = useForceUpdate();

  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery();

  const [
    updateRiskAnalisysAndActionYearsForLoggedInCustomer, // This is the mutation trigger
    { isLoading: isUpdatingUserDataStorageForLoggedInCustomer }, // This is the destructured mutation result
  ] = useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation();

  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateEditRiskAnalisysAndActionTaskItem, setStateEditRiskAnalisysAndActionTaskItem] = useState(new RiskAnalisysAndActionTaskItem(uuidv4()));
  const [stateRiskAnalisysAndActions, setStateRiskAnalisysAndActions] = useState([]);
  const [stateOpenAccordions, setStateOpenAccordions] = useState([false]);
  const [stateAutosaving, setStateAutosaving] = useState({ riskAnalisysAndActionId: 0, riskAnalisysAndActionItemId: 0, saved: true });

  useEffect(() => {
    async function asyncUseEffect() {
      //Get active RiskAnalisysAndActionYear from Database and put it into local state.
      const allRiskAnalisysAndActions = await getAllRiskAnalisysAndActions();

      setStateRiskAnalisysAndActions( allRiskAnalisysAndActions );

      //Add true for the first accordion and false for the rest.
      const openAccordions = [false];

      for(let i = 1; i < Object.values(allRiskAnalisysAndActions).length; i++){
        openAccordions.push(false);
      }

      setStateOpenAccordions( openAccordions );

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, []);

  //Only run if riskAnalisysAndActionYears is changed.
  useEffect(() => {
    async function asyncUseEffect() {
      //Get active RiskAnalisysAndActionYear from Database and put it into local state.
      const allRiskAnalisysAndActions = await getAllRiskAnalisysAndActions();

      setStateRiskAnalisysAndActions( allRiskAnalisysAndActions );
    }

    asyncUseEffect();
  }, [riskAnalisysAndActionYears]);

  async function getAllRiskAnalisysAndActions(){
    //Get active RiskAnalisysAndActionYear from Database.

    var localRiskAnalisysAndActionYears = null;
    
    if ( riskAnalisysAndActionYears ){
      localRiskAnalisysAndActionYears = riskAnalisysAndActionYears;
    } else {
      localRiskAnalisysAndActionYears = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();
    }

    const activeRiskAnalisysAndActionYear = await getActiveRiskAnalisysAndActionYear(localRiskAnalisysAndActionYears);

    const allRiskAnalisysAndActions = await getAllRiskAnalisysAndActionsForRiskAnalisysAndActionYear( activeRiskAnalisysAndActionYear );

    return allRiskAnalisysAndActions;
  }

  async function toggleOpenAccordion(id){
    //Get the array of accordions.
    const openAccordions = stateOpenAccordions;

    //Toggle the one with the id.
    openAccordions[id] = !openAccordions[id];

    //Set the state.
    setStateOpenAccordions( openAccordions );

    //Force a rerender.
    forceUpdate();
  }

  async function editRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionId, riskAnalisysAndActionItemId, riskAnalisysAndActionTaskItemId ){
    //Get the correct riskAnalisysAndAction.
    const riskAnalisysAndAction = Object.values(stateRiskAnalisysAndActions).find( riskAnalisysAndAction => riskAnalisysAndAction.id === riskAnalisysAndActionId );

    //Get the correct riskAnalisysAndActionItem.
    const riskAnalisysAndActionItem = riskAnalisysAndAction.riskAnalisysAndActionItems.find( riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

    //Get the correct riskAnalisysAndActionTaskItem.
    const riskAnalisysAndActionTaskItemToEdit = riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.find( riskAnalisysAndActionTaskItem => riskAnalisysAndActionTaskItem.id === riskAnalisysAndActionTaskItemId);

    setStateEditRiskAnalisysAndActionTaskItem( riskAnalisysAndActionTaskItemToEdit );
  }

  async function saveEditedRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionId, riskAnalisysAndActionItemId, riskAnalisysAndActionTaskItemId ){
    //Get the correct riskAnalisysAndAction.
    const riskAnalisysAndAction = Object.values(stateRiskAnalisysAndActions).find( riskAnalisysAndAction => riskAnalisysAndAction.id === riskAnalisysAndActionId );

    //Get the correct riskAnalisysAndActionItem.
    var updatedRiskAnalisysAndActionItem = JSON.parse( JSON.stringify( riskAnalisysAndAction.riskAnalisysAndActionItems.find( riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId) ) );

    //Get the index of the item we want to edit. We assume that it is still there since we were able to display it when the user pressed the edit button.
    const indexOfRiskAnalisysAndActionItemToEdit = updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.findIndex( riskAnalisysAndActionTaskItem => riskAnalisysAndActionTaskItem.id === riskAnalisysAndActionTaskItemId );

    //Set the edited properties.
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].fixed = stateEditRiskAnalisysAndActionTaskItem.fixed;
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].fixedDate = stateEditRiskAnalisysAndActionTaskItem.fixedDate;
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].comment = stateEditRiskAnalisysAndActionTaskItem.comment;
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].changedAt = new Date();

    //Update the RiskAnalisysAndActionItem and save it to the DB.
    const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();

    const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYearsLazy, riskAnalisysAndAction.chapterSectionTypeName, updatedRiskAnalisysAndActionItem );

    //Update database.
    var result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );    

    //Get active RiskAnalisysAndActionYear from Database and put it into local state.
    const allRiskAnalisysAndActions = await getAllRiskAnalisysAndActions();

    setStateRiskAnalisysAndActions( allRiskAnalisysAndActions );
    
    //Set the current edit RiskAnalisysAndActionItem to a new one (should not be null).
    const editRiskAnalisysAndActionTaskItem = new RiskAnalisysAndActionTaskItem(uuidv4());

    setStateEditRiskAnalisysAndActionTaskItem(editRiskAnalisysAndActionTaskItem);
  }

  async function cancelEditRiskAnalisysAndActionTaskItemButtonOnClick(){
    const editRiskAnalisysAndActionTaskItem = new RiskAnalisysAndActionTaskItem(uuidv4());

    setStateEditRiskAnalisysAndActionTaskItem( editRiskAnalisysAndActionTaskItem);
  }

  async function setFixedToTrueCheckboxOnChange(){
    if( stateEditRiskAnalisysAndActionTaskItem.fixedDate === null || stateEditRiskAnalisysAndActionTaskItem.fixedDate === "" ){
      //Get todays date as date.
      const todaysDate = new Date();

      //Get todays date as string
      const todaysDateAsString = todaysDate.toLocaleDateString("sv-SE");

      setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, fixedDate: todaysDate });
      forceUpdate();
    }

    setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, fixed: true });
  }

  async function setFixedToFalseCheckboxOnChange(){
    setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, fixedDate: null });
    setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, fixed: false });
  }

  async function updateLocalStateRiskAnalisysAndActionItemFollowupText( riskAnalisysAndActionId, riskAnalisysAndActionItemId, followUpText ){
    setStateAutosaving({ riskAnalisysAndActionId: riskAnalisysAndActionId, riskAnalisysAndActionItemId: riskAnalisysAndActionItemId, saved: false });

    let riskAnalisysAndActions = JSON.parse( JSON.stringify( stateRiskAnalisysAndActions ) );

    //Get the index of the correct riskAnalisysAndAction.
    const indexOfRiskAnalisysAndAction = Object.values(riskAnalisysAndActions).findIndex( riskAnalisysAndAction => riskAnalisysAndAction.id === riskAnalisysAndActionId );

    //Get the index of the correct riskAnalisysAndActionItem.
    const indexOfRiskAnalisysAndActionItem = riskAnalisysAndActions[indexOfRiskAnalisysAndAction].riskAnalisysAndActionItems.findIndex( riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

    //Set the edited properties.
    riskAnalisysAndActions[indexOfRiskAnalisysAndAction].riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItem].followUpText = followUpText;

    //Set the current edit RiskAnalisysAndActionItem.
    setStateRiskAnalisysAndActions(riskAnalisysAndActions);

    forceUpdate();
  } 

  async function updateRiskAnalisysAndActionFollowupText( riskAnalisysAndActionId, riskAnalisysAndActionItemId, followUpText ){
    //Get the correct riskAnalisysAndAction.
    const riskAnalisysAndActionToUpdate = JSON.parse( JSON.stringify( Object.values(stateRiskAnalisysAndActions).find( riskAnalisysAndAction => riskAnalisysAndAction.id === riskAnalisysAndActionId ) ) );

    //Get the index of the correct riskAnalisysAndActionItem.
    var riskAnalisysAndActionItemToUpdate = riskAnalisysAndActionToUpdate.riskAnalisysAndActionItems.find( riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

    //Set the edited properties.
    riskAnalisysAndActionItemToUpdate.followUpText = followUpText;

    //Update the RiskAnalisysAndActionItem and save it to the DB.
    const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();

    const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYearsLazy, riskAnalisysAndActionToUpdate.chapterSectionTypeName, riskAnalisysAndActionItemToUpdate );

    //Update database.
    var result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

    setStateAutosaving({ riskAnalisysAndActionId: riskAnalisysAndActionId, riskAnalisysAndActionItemId: riskAnalisysAndActionItemId, saved: true });

    forceUpdate();
  }

  if (stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="sectionTopPadding"></div>
        <div className="outerContainerForAccordion">
          <div className="chapterStep">            
            <div className="chapterStepTitle">
              <h2><TwKey textKey="10280" /></h2>
            </div>
            <div className="chapterStepContent">
              <>
                <p style={{ paddingTop: "15px" }}><TwKey textKey="10268"/></p>
                {
                  Object.values(stateRiskAnalisysAndActions).map(( riskAnalisysAndAction, riskAnalisysAndActionIdx ) => (
                    <>
                      {
                        riskAnalisysAndAction.risksDefinedAndAnalyzed ?
                          <Accordion
                            key={riskAnalisysAndActionIdx}
                            elevation={0}
                            className={ riskAnalisysAndActionIdx % 2 == 0 ? "backGroundColor5" : "accordionEveryOther" }
                            expanded={ stateOpenAccordions[riskAnalisysAndActionIdx] }
                          >
                            <AccordionSummary
                              className="accordionSummary"
                              style={{ padding: "0px" }}
                              expandIcon={
                                <ExpandMore
                                  style={{cursor: "pointer" }}
                                  onClick={ async () => await toggleOpenAccordion(riskAnalisysAndActionIdx)}
                                />
                                }
                                sx={{ cursor: 'unset !important' }}
                            >
                              <div className="accordionSummary">
                                <h2><TwKey textKey={riskAnalisysAndAction.titleTextKey}/>&nbsp;<InfoText textKey={riskAnalisysAndAction.tasksChapterInformationTextKey}/></h2>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails style={{padding: "0" }}>
                              {

                                Object.values(riskAnalisysAndAction.riskAnalisysAndActionItems).length > 0 ?
                                  Object.values(riskAnalisysAndAction.riskAnalisysAndActionItems).map( ( riskAnalisysAndActionItem, riskAnalisysAndActionItemIdx ) => (
                                    <Fragment key={riskAnalisysAndActionItemIdx}>
                                      <h3><TwKey textKey="10080"/>&nbsp;{riskAnalisysAndActionItem.risk}</h3>
                                      {
                                        Object.values(riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems).length > 0 ?
                                          <>
                                            <h4><TwKey textKey="10266"/><InfoText textKey="10380" /></h4>
                                            <Table className="superRTable">
                                              <TableHead style={{ backgroundColor: "#FFFFFF" }}>
                                                <TableRow style={{ backgroundColor: "#FFFFFF" }}>
                                                  <TableCell style={{ backgroundColor: "#FFFFFF" }}>
                                                    <div className="textWithAlignedIcon"><b><TwKey textKey="10131" />&nbsp;<InfoText textKey="10240" /></b></div>
                                                  </TableCell>
                                                  <TableCell>
                                                    <div className="textWithAlignedIcon"><b><TwKey textKey="10132" />&nbsp;<InfoText textKey="10241" /></b></div>
                                                  </TableCell>
                                                  <TableCell>
                                                    <div className="textWithAlignedIcon"><b><TwKey textKey="10133" />&nbsp;<InfoText textKey="10242" /></b></div>
                                                  </TableCell>
                                                  <TableCell>
                                                    <div className="textWithAlignedIcon"><b><TwKey textKey="10134" />&nbsp;<InfoText textKey="10243" /></b></div>
                                                  </TableCell>
                                                  <TableCell>
                                                    <div className="textWithAlignedIcon"><b><TwKey textKey="10157" />&nbsp;<InfoText textKey="10244" /></b></div>
                                                  </TableCell>
                                                  <TableCell>
                                                    <div className="textWithAlignedIcon"><b><TwKey textKey="10135" />&nbsp;<InfoText textKey="10245" /></b></div>
                                                  </TableCell>
                                                  <TableCell>
                                                    <div className="textWithAlignedIcon"><b><TwKey textKey="10136" />&nbsp;<InfoText textKey="10246" /></b></div>
                                                  </TableCell>
                                                  <TableCell>
                                                    <div style={{ paddingTop: "10px"}} className="textWithAlignedIcon"><b>&nbsp;</b></div>
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {
                                                  Object.values(riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems).map( ( riskAnalisysAndActionTaskItem, riskAnalisysAndActionTaskItemIdx ) => (
                                                    <TableRow key={riskAnalisysAndActionTaskItemIdx} style={{verticalAlign: "Middle"}}>
                                                      <TableCell>
                                                        {
                                                          riskAnalisysAndActionTaskItem.task
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          riskAnalisysAndActionTaskItem.responsible
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                        riskAnalisysAndActionTaskItem.ongoing ?
                                                          <CheckBoxOutlined className="checkBoxColorDisabled" />
                                                        :
                                                          <CheckBoxOutlineBlank className="checkBoxColorDisabled" />
                                                      }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          riskAnalisysAndActionTaskItem.ongoing ?
                                                            " "
                                                          :
                                                            riskAnalisysAndActionTaskItem.followUpDate
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                                            stateEditRiskAnalisysAndActionTaskItem.fixed ?
                                                            <IconButton
                                                              className="checkBoxButtonNoMarginOrPadding"
                                                              onClick={ async () => await setFixedToFalseCheckboxOnChange() }
                                                            >
                                                              <CheckBoxOutlined className="checkBoxColor" />
                                                            </IconButton>
                                                            :
                                                            <IconButton
                                                              className="checkBoxButtonNoMarginOrPadding"
                                                              onClick={ async () => await setFixedToTrueCheckboxOnChange() }
                                                            >
                                                              <CheckBoxOutlineBlank className="checkBoxColor" />
                                                            </IconButton>
                                                          :
                                                            riskAnalisysAndActionTaskItem.fixed ?
                                                              <CheckBoxOutlined className="checkBoxColorDisabled" />
                                                            :
                                                              <CheckBoxOutlineBlank className="checkBoxColorDisabled" />
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                                            <TextField
                                                              variant="outlined"
                                                              color="primary"
                                                              type="date"
                                                              size="small"
                                                              value={stateEditRiskAnalisysAndActionTaskItem.fixedDate}
                                                              onChange={(event) => setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, fixedDate: event.target.value })}
                                                            />
                                                          :
                                                            riskAnalisysAndActionTaskItem.fixedDate
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {
                                                          stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                                            <TextFieldWithPlaceholder
                                                              placeholderTextKey="10137"
                                                              value={stateEditRiskAnalisysAndActionTaskItem.comment}
                                                              onChange={(event) => setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, comment: event.target.value })}
                                                            />
                                                          :
                                                            riskAnalisysAndActionTaskItem.comment
                                                        }
                                                      </TableCell>
                                                      <TableCell  style={{ textAlign: "Right", whiteSpace: "nowrap"}}>
                                                        {
                                                          stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                                            <>
                                                              <FormControl>
                                                                <SaveButton
                                                                  buttonTextKey="10138"
                                                                  toolTipTitleTextKey="10139"
                                                                  disabled={ stateEditRiskAnalisysAndActionTaskItem.task !== "" ? false : true }
                                                                  onClick={ async () => await saveEditedRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndAction.id, riskAnalisysAndActionItem.id, riskAnalisysAndActionTaskItem.id) }
                                                                />
                                                              </FormControl>
                                                              &nbsp;
                                                              <FormControl>
                                                                <CancelButton
                                                                  buttonTextKey="10140"
                                                                  toolTipTitleTextKey="10141"
                                                                  onClick={ async () => await cancelEditRiskAnalisysAndActionTaskItemButtonOnClick() }
                                                                />
                                                              </FormControl>
                                                            </>
                                                          :
                                                            <>
                                                              <FormControl>
                                                                <EditButton
                                                                  buttonTextKey = "10142"
                                                                  toolTipTitleTextKey = "10143"
                                                                  onClick={ async () => await editRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndAction.id, riskAnalisysAndActionItem.id, riskAnalisysAndActionTaskItem.id) }
                                                                />
                                                              </FormControl>
                                                              {/* &nbsp;
                                                              <FormControl>
                                                                <DeleteButton
                                                                  buttonTextKey = ""
                                                                  toolTipTitleTextKey = ""
                                                                  onClick={ () => PopupConfirmYesNoWithValue( "", riskAnalisysAndActionTaskItem.task, async () => await deleteRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionTaskItem.id )) }
                                                                />
                                                              </FormControl> */}
                                                            </>
                                                        }
                                                      </TableCell>
                                                    </TableRow>
                                                  ))
                                                }
                                              </TableBody>
                                            </Table>
                                          </>
                                        :
                                          riskAnalisysAndActionItem.remedy ? 
                                            <TwKey textKey="10159"/>
                                          :
                                            <TwKey textKey="10267"/>
                                      }
                                      <br/>
                                      <h5><TwKey textKey="10264"/><InfoText textKey="10381" /></h5>
                                      <div style={{ width: "100%", paddingBottom: "1px" }} className="textInputBox">
                                        <MultiLineTextFieldWithPlaceholder
                                          placeholderTextKey="10265"
                                          value={ riskAnalisysAndActionItem.followUpText }
                                          onChange={(event) => updateLocalStateRiskAnalisysAndActionItemFollowupText( riskAnalisysAndAction.id, riskAnalisysAndActionItem.id, event.target.value )}
                                          minRows={5}
                                          maxRows={10}
                                        />
                                        <Autosave data={ riskAnalisysAndActionItem.followUpText } onSave={ async () => await updateRiskAnalisysAndActionFollowupText( riskAnalisysAndAction.id, riskAnalisysAndActionItem.id, riskAnalisysAndActionItem.followUpText )} />
                                        <p>
                                          {
                                            stateAutosaving.riskAnalisysAndActionId === riskAnalisysAndAction.id && stateAutosaving.riskAnalisysAndActionItemId === riskAnalisysAndActionItem.id && stateAutosaving.saved === false ?
                                              <p><TwKey textKey="10389"/></p>
                                            :
                                              <p><TwKey textKey="10390"/></p>
                                          }
                                        </p>
                                      </div>
                                    </Fragment>
                                  ))
                                :
                                  <TwKey textKey="10158"/>
                              }
                          </AccordionDetails>
                        </Accordion>
                      :
                        ""
                      }
                    </>
                  ))                
                } 
              </>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RiskActions;