import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Autosave } from 'react-autosave';
import { v4 as uuidv4 } from 'uuid';
import useForceUpdate from 'use-force-update';
import {
  useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation
} from 'API/UserDataStorageAPI';
import {
  getActiveRiskAnalisysAndActionYear,
  getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear,
  addRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear,
  updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear
} from 'API/SelectOrModifyUserDataStorageItems';
import Loading from "Components/Loading";
import ChapterSectionStepBar from "Components/Steps/ChapterSectionStepBar";
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, Select, MenuItem, Grid, Paper } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { TextFieldWithPlaceholder, MultiLineTextFieldWithPlaceholder, RequiredField, InfoText, TwKey, AddButton, EditButton, SaveButton, CancelButton, DeleteButton, CheckBoxToggleButton, PopupConfirmYesNoWithValue } from "Components/StandardTextComponentsWithTranslation";
import { RiskAnalisysAndActionTaskItem } from "Classes/UserData";
import { GetChapterSectionStepUrl } from "Util/Utils";
import { ChapterSectionStepTypeUrls } from "Routes/Urls";

const RiskAnalisysAndAction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const [stateIsLoading, setStateIsLoading] = useState(false);
  const [stateCurrentRiskAnalisysAndActionItemAnalisysText, setStateCurrentRiskAnalisysAndActionItemAnalisysText] = useState("");
  const [stateSelectedRiskAnalisysAndAction, setStateSelectedRiskAnalisysAndAction] = useState( null );
  const [stateSelectedRiskAnalisysAndActionItem, setStateSelectedRiskAnalisysAndActionItem] = useState( null );
  const [stateNewRiskAnalisysAndActionTaskItem, setStateNewRiskAnalisysAndActionTaskItem] = useState( new RiskAnalisysAndActionTaskItem(uuidv4()) );
  const [stateEditRiskAnalisysAndActionTaskItem, setStateEditRiskAnalisysAndActionTaskItem] = useState( new RiskAnalisysAndActionTaskItem(uuidv4()) );
  const [stateSaved, setStateSaved] = useState( true );
  

  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(  );

  const [
    updateRiskAnalisysAndActionYearsForLoggedInCustomer, // This is the mutation trigger
    { isLoading: isUpdatingUserDataStorageForLoggedInCustomer }, // This is the destructured mutation result
  ] = useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation();

  const appState = useSelector((state) => state.settings.appState);
  const dispatch = useDispatch();
  
  async function asyncGetSelectedRiskAnalisysAndAction(){
    var localRiskAnalisysAndActionYears = null;

    if( riskAnalisysAndActionYears ){
      localRiskAnalisysAndActionYears = riskAnalisysAndActionYears;
    } else {
      localRiskAnalisysAndActionYears = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();
    }

    const selectedRiskAnalisysAndAction = await getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( localRiskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );

    return selectedRiskAnalisysAndAction;
  }

  async function asyncGetSelectedRiskAnalisysAndActionAndPutInState(){
    const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();

    setStateSelectedRiskAnalisysAndAction( selectedRiskAnalisysAndAction );

    return selectedRiskAnalisysAndAction;
  }

  async function updateRiskAnalisysAndActionYearsForLoggedInCustomerAndPutSelectedRiskAnalisysAndActionIntoState( updatedRiskAnalisysAndActionYears ){
    const updateResult = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( updatedRiskAnalisysAndActionYears );

    const selectedRiskAnalisysAndAction = await getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( updatedRiskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );

    setStateSelectedRiskAnalisysAndAction( selectedRiskAnalisysAndAction );

    if( id ){
      const selectedRiskAnalisysAndActionItem = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === id);
      
      setStateSelectedRiskAnalisysAndActionItem( selectedRiskAnalisysAndActionItem );
    }

    return true;
  }

  useEffect(() => {
    console.log("useEffect");

    async function asyncUseEffect(){
      const selectedRiskAnalisysAndActionLazy = await asyncGetSelectedRiskAnalisysAndActionAndPutInState();

      //Check if the user has not selected a risk item and select the first one if not.
      if ( id ){    
        const selectedRiskAnalisysAndActionItem = selectedRiskAnalisysAndActionLazy.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === id);
      
        setStateSelectedRiskAnalisysAndActionItem( selectedRiskAnalisysAndActionItem );

        setStateCurrentRiskAnalisysAndActionItemAnalisysText( selectedRiskAnalisysAndActionItem?.analisys );
      } else {
        const selectedRiskAnalisysAndActionItems = selectedRiskAnalisysAndActionLazy.riskAnalisysAndActionItems;
        
        if(selectedRiskAnalisysAndActionItems.length > 0){
          const riskAnalisysAndActionItemWithId = selectedRiskAnalisysAndActionItems[0].id;

          setStateSelectedRiskAnalisysAndActionItem( selectedRiskAnalisysAndActionItems[0] );

          setStateCurrentRiskAnalisysAndActionItemAnalisysText( selectedRiskAnalisysAndActionItems[0]?.analisys );

          const selectedRiskItemUrl = GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.riskAnalysisAndAction, riskAnalisysAndActionItemWithId );

          //Navigate to the first ID of the current RiskAnalisysAndActionItem in active RiskAnalisysAndActionYear.
          return navigate( selectedRiskItemUrl );
        }
      }
    }

    asyncUseEffect();

    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading( false );
  }, []);

  //Only run if riskAnalisysAndActionYears has been updated.
  useEffect(() => {
    async function asyncUseEffect(){
      if ( id ){
        const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndActionAndPutInState();
      
        const currentRiskAnalisysAndActionItem = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === id);

        setStateSelectedRiskAnalisysAndActionItem( currentRiskAnalisysAndActionItem );
        
        setStateCurrentRiskAnalisysAndActionItemAnalisysText( currentRiskAnalisysAndActionItem?.analisys );
      }
    }

    asyncUseEffect();
  }, [ riskAnalisysAndActionYears ]);

  async function changeSelectedRiskItem( event ){
    if( stateSelectedRiskAnalisysAndActionItem !== undefined ){
      const selectedId = event.target.value;

      const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndActionAndPutInState();

      const currentRiskAnalisysAndActionItem = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === selectedId);
      
      setStateSelectedRiskAnalisysAndActionItem( currentRiskAnalisysAndActionItem );

      setStateCurrentRiskAnalisysAndActionItemAnalisysText( currentRiskAnalisysAndActionItem.analisys );

      const selectedRiskItemUrl = GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.riskAnalysisAndAction, selectedId );
      navigate(selectedRiskItemUrl);

      //Force the component to rerender.
      forceUpdate();
    }
  }

  async function updateStateCurrentRiskAnalisysAndActionItemAnalisysText( value ){
    setStateSaved(false);

    setStateCurrentRiskAnalisysAndActionItemAnalisysText( value );
  }

  async function updateRiskAnalisysAndActionItemAnalisysText(){
    
    const analisysText = stateCurrentRiskAnalisysAndActionItemAnalisysText;

    if( stateSelectedRiskAnalisysAndActionItem !== undefined ){
      //Get the current RiskAnalisysAndActionItem.
      const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();
      
      const updatedRiskAnalisysAndActionItem = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === id);

      if( updatedRiskAnalisysAndActionItem ){
        updatedRiskAnalisysAndActionItem.analisys = analisysText;

        //Update the RiskAnalisysAndActionItem in the DB.
        const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, updatedRiskAnalisysAndActionItem );

        const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomerAndPutSelectedRiskAnalisysAndActionIntoState( riskAnalisysAndActionYearsToUpdate );

        setStateCurrentRiskAnalisysAndActionItemAnalisysText( analisysText );
      }
    }

    setStateSaved(true);

    //Force the component to rerender.
    forceUpdate();
  }

  async function remedyCurrentRiskAnalisysAndActionItemButtonOnClick(){
    const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();
    
    //Get the current RiskAnalisysAndActionItem.
    const updatedRiskAnalisysAndActionItem = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === id);

    //Set it to the oposite of what it is now.
    updatedRiskAnalisysAndActionItem.remedy = true;

    //Update the RiskAnalisysAndActionItem in the DB.
    const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, updatedRiskAnalisysAndActionItem );
        
    const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomerAndPutSelectedRiskAnalisysAndActionIntoState( riskAnalisysAndActionYearsToUpdate );

    //Force the component to rerender.
    forceUpdate();
  }

  async function doNotRemedyCurrentRiskAnalisysAndActionItemButtonOnClick(){
    const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();

    //Get the current RiskAnalisysAndActionItem.
    const updatedRiskAnalisysAndActionItem = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === id);

    //Set it to the oposite of what it is now.
    updatedRiskAnalisysAndActionItem.remedy = false;

    //Reset the array of riskAnalisysAndActionTasks.
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems = [];

    //Update the RiskAnalisysAndActionItem in the DB.
    const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, updatedRiskAnalisysAndActionItem );
        
    const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomerAndPutSelectedRiskAnalisysAndActionIntoState( riskAnalisysAndActionYearsToUpdate );

    //Force the component to rerender.
    forceUpdate();
  }

  async function addNewRiskAnalisysAndActionTaskItemOnClick(){
    addNewRiskAnalisysAndActionTaskItem( stateNewRiskAnalisysAndActionTaskItem.task, stateNewRiskAnalisysAndActionTaskItem.responsible, stateNewRiskAnalisysAndActionTaskItem.ongoing, stateNewRiskAnalisysAndActionTaskItem.followUpDate );
  }

  async function addNewRiskAnalisysAndActionTaskItem( task, responsible, ongoing, followUpDate ){
    const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();

    //Get the current RiskAnalisysAndActionItem.
    const updatedRiskAnalisysAndActionItem = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.find(riskAnalisysAndActionItem => riskAnalisysAndActionItem.id === id);    

    const newRiskAnalisysAndActionTaskItemToAdd = new RiskAnalisysAndActionTaskItem(uuidv4());

    newRiskAnalisysAndActionTaskItemToAdd.riskAnalisysAndActionItemId = updatedRiskAnalisysAndActionItem.id;
    newRiskAnalisysAndActionTaskItemToAdd.task = task;
    newRiskAnalisysAndActionTaskItemToAdd.responsible = responsible;
    newRiskAnalisysAndActionTaskItemToAdd.ongoing = ongoing;
    newRiskAnalisysAndActionTaskItemToAdd.followUpDate = followUpDate;

    //Add the new task to riskAnalisysAndActionTaskItems in the updateRiskAnalisysAndActionItem.
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.push(newRiskAnalisysAndActionTaskItemToAdd);

    //Update the RiskAnalisysAndActionItem in the DB.
    const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, updatedRiskAnalisysAndActionItem );
        
    const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomerAndPutSelectedRiskAnalisysAndActionIntoState( riskAnalisysAndActionYearsToUpdate );

    //Reset newRiskAnalisysAndActionTaskItem
    const newRiskAnalisysAndActionTaskItem = new RiskAnalisysAndActionTaskItem(uuidv4());

    setStateNewRiskAnalisysAndActionTaskItem( newRiskAnalisysAndActionTaskItem );

    //Foce update the control to show the new item.
    forceUpdate();
  }

  async function editRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionTaskItemId ){
    const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();
    
    //Filter out the correct RiskAnalisysAndActionItem, i.e. the one that contains the RiskAnalisysAndActionItem with the id riskAnalisysAndActionItemId from the redux store.
    const filteredRiskAnalisysAndActionItems = stateSelectedRiskAnalisysAndAction.riskAnalisysAndActionItems.filter( (riskAnalisysAndActionItem) => riskAnalisysAndActionItem.id === stateSelectedRiskAnalisysAndActionItem.id);

    //We should only get one match since we filtered on the riskAnalisysAndActionItemId, therefore we assume that the RiskAnalisysAndActionItem we want is on spot 0.
    const currentRiskAnalisysAndActionItem = filteredRiskAnalisysAndActionItems[0];

    //Filter out the correct RiskAnalisysAndActionTaskItem, i.e. the one that contains the riskAnalisysAndActionTaskItem with the id riskAnalisysAndActionTaskItemId.
    const editRiskAnalisysAndActionTaskItems = currentRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.filter( ( riskAnalisysAndActionTaskItem) => riskAnalisysAndActionTaskItem.id === riskAnalisysAndActionTaskItemId);

    //We should only get one match since we filtered on the riskAnalisysAndActionTaskItemId, therefore we assume that the riskAnalisysAndActionTaskItem we want is on spot 0.
    const riskAnalisysAndActionTaskItemToEdit = editRiskAnalisysAndActionTaskItems[0];

    setStateEditRiskAnalisysAndActionTaskItem( riskAnalisysAndActionTaskItemToEdit );

    //Force update the control to show that we want to edit an item.
    forceUpdate();  

    const selectedRiskItemUrl = GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.riskAnalysisAndAction, id );

    //Navigate to the first ID of the current RiskAnalisysAndActionItem in active RiskAnalisysAndActionYear.
    return navigate( selectedRiskItemUrl );
  }

  async function saveEditedRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionTaskItemId){
    const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();

    //Filter out the correct RiskAnalisysAndActionItem, i.e. the one that contains the RiskAnalisysAndActionItem with the id riskAnalisysAndActionItemId from the redux store.
    const filteredRiskAnalisysAndActionItems = stateSelectedRiskAnalisysAndAction.riskAnalisysAndActionItems.filter( (riskAnalisysAndActionItem) => riskAnalisysAndActionItem.id === stateSelectedRiskAnalisysAndActionItem.id);

    //We should only get one match since we filtered on the riskAnalisysAndActionItemId, therefore we assume that the RiskAnalisysAndActionItem we want is on spot 0.
    const updatedRiskAnalisysAndActionItem = filteredRiskAnalisysAndActionItems[0];

    //Get the index of the item we want to edit. We assume that it is still there since we were able to display it when the user pressed the edit button.
    const indexOfRiskAnalisysAndActionItemToEdit = updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.findIndex( riskAnalisysAndActionTaskItem => riskAnalisysAndActionTaskItem.id === riskAnalisysAndActionTaskItemId );

    //Set the edited properties.
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].task = stateEditRiskAnalisysAndActionTaskItem.task;
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].responsible = stateEditRiskAnalisysAndActionTaskItem.responsible;
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].ongoing = stateEditRiskAnalisysAndActionTaskItem.ongoing;
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].followUpDate = stateEditRiskAnalisysAndActionTaskItem.followUpDate;
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionItemToEdit].changedAt = new Date();

    //Update the RiskAnalisysAndActionItem in the DB.
    const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, updatedRiskAnalisysAndActionItem );
        
    const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomerAndPutSelectedRiskAnalisysAndActionIntoState( riskAnalisysAndActionYearsToUpdate );
    
    //Set the current edit RiskAnalisysAndActionItem to a new one (should not be null).
    setStateEditRiskAnalisysAndActionTaskItem( new RiskAnalisysAndActionTaskItem(uuidv4()) );

    forceUpdate();
  }

  async function cancelEditRiskAnalisysAndActionTaskItemButtonOnClick(){
    setStateEditRiskAnalisysAndActionTaskItem( new RiskAnalisysAndActionTaskItem(uuidv4()) );

    //Force update to show that the edit was canceled.
    forceUpdate();
  }

  async function deleteRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionTaskItemId ){
    const selectedRiskAnalisysAndAction = await asyncGetSelectedRiskAnalisysAndAction();

    //Filter out the correct RiskAnalisysAndActionItem, i.e. the one that contains the RiskAnalisysAndActionItem with the id riskAnalisysAndActionItemId from the redux store.
    const filteredRiskAnalisysAndActionItems = stateSelectedRiskAnalisysAndAction.riskAnalisysAndActionItems.filter( (riskAnalisysAndActionItem) => riskAnalisysAndActionItem.id === stateSelectedRiskAnalisysAndActionItem.id);

    //We should only get one match since we filtered on the riskAnalisysAndActionItemId, therefore we assume that the RiskAnalisysAndActionItem we want is on spot 0.
    const updatedRiskAnalisysAndActionItem = filteredRiskAnalisysAndActionItems[0];

    //Filter out all elements that does not contain the specified riskAnalisysAndActionTaskItemId
    const updatedRiskAnalisysAndActionTaskItems = updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.filter( riskAnalisysAndActionTaskItem => riskAnalisysAndActionTaskItem.id !== riskAnalisysAndActionTaskItemId );

    //Set the new array in the updatedRiskAnalisysAndActionItem.
    updatedRiskAnalisysAndActionItem.riskAnalisysAndActionTaskItems = updatedRiskAnalisysAndActionTaskItems;

    //Update the RiskAnalisysAndActionItem in the DB.
    const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, updatedRiskAnalisysAndActionItem );
        
    const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomerAndPutSelectedRiskAnalisysAndActionIntoState( riskAnalisysAndActionYearsToUpdate );

    //Force update the control to show that the item was deleted.
    forceUpdate();
  }
    
  if ( stateIsLoading || riskAnalisysAndActionYearsIsLoading || stateSelectedRiskAnalisysAndAction === null ){
    return <Loading />;
  } else {
    return (
      <>
        <div className="innerContentWrapper">
          <ChapterSectionStepBar chapterSectionStepBarClickable={ true } />
          <div className="outerContainerForAccordion">
            <div className="chapterStep">
              <div className="chapterStepTitle">
                <div className="accordionSummary">
                  <h2><TwKey textKey="10010" />&nbsp;<InfoText textKey="10224"/></h2>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", minWidth: "400px" }} >
                  </div>
                </div>
              </div>
              <div className="chapterStepContent">
                <Grid container spacing={0} className="basicPaper" style={{ paddingBottom: "7px"}}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <Paper elevation={0} className="basicPaper">
                        <span style={{ paddingRight: "2px", color: "#000000"}}><b><TwKey textKey="10069" />&nbsp;<InfoText textKey="10225"/></b></span>
                      </Paper>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      <Paper elevation={0} className="basicPaper">
                        <b><TwKey textKey="10024" />&nbsp;<InfoText textKey="10226"/></b>
                      </Paper>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Paper elevation={0} className="basicPaper">
                        <b><TwKey textKey="10025" />&nbsp;<InfoText textKey="10227"/></b>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="basicPaper">
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <Paper elevation={0} className="basicPaper">
                        <FormControl variant="outlined" size="small" fullWidth={ true }>
                          <Select
                            style={{backgroundColor: "#FFFFFF"}}  
                            variant="outlined"
                            displayEmpty
                            fullWidth={ true }
                            value={id ? id : ""}
                            onChange={ async (event)  => await changeSelectedRiskItem(event) }
                            inputProps={{ 'aria-label': '' }}
                          >
                            {
                              stateSelectedRiskAnalisysAndAction.riskAnalisysAndActionItems.map(( riskAnalisysAndActionItem, idx ) => (
                                <MenuItem
                                  key={riskAnalisysAndActionItem.id}
                                  value={riskAnalisysAndActionItem.id}
                                  >
                                  {riskAnalisysAndActionItem.risk}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Paper>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      <Paper elevation={0} className="basicPaper">
                        {stateSelectedRiskAnalisysAndActionItem?.consequence}
                      </Paper>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Paper elevation={0} className="basicPaper">
                        <CheckBoxToggleButton 
                          currentStateIsTrue={ stateSelectedRiskAnalisysAndActionItem?.remedy }
                          buttonTextKeyIfTrue="10070"
                          toolTipTitleTextKeyIfTrue="10382"
                          onClickIfTrue={ () => PopupConfirmYesNoWithValue( "10114", stateSelectedRiskAnalisysAndActionItem.risk, async () => await doNotRemedyCurrentRiskAnalisysAndActionItemButtonOnClick() ) }
                          disabledIfTrue={ stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed }
                          buttonTextKeyIfFalse="10071"
                          toolTipTitleTextKeyIfFalse="10383"
                          onClickIfFalse={ async () => await remedyCurrentRiskAnalisysAndActionItemButtonOnClick() }
                          disabledIfFalse={ stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed }
                        ></CheckBoxToggleButton>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <br/>
                <hr className="seperatorGrey" />
                <div className="textWithAlignedIcon">
                  <h4>
                    <TwKey textKey="10004" />
                    <RequiredField />
                    &nbsp;
                    <InfoText textKey={ appState.currentChapterSectionTypeWithUrl.chapterSectionType.RiskAnalisysAndActionAnalisysInfoTextKey } />
                  </h4>
                </div>
                
                <div style={{ width: "100%", paddingBottom: "1px" }} className="textInputBox">
                  <MultiLineTextFieldWithPlaceholder
                    placeholderTextKey="10125"
                    value={ stateCurrentRiskAnalisysAndActionItemAnalisysText }
                    onChange={ async (event) => updateStateCurrentRiskAnalisysAndActionItemAnalisysText( event.target.value )}
                    minRows={5}
                    maxRows={10}
                    disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                  />
                  <Autosave data={ stateCurrentRiskAnalisysAndActionItemAnalisysText } onSave={ async () => await updateRiskAnalisysAndActionItemAnalisysText() } />
                  {
                    stateSaved ?
                      <p><TwKey textKey="10388"/></p>
                    :
                      <p><TwKey textKey="10387"/></p>
                  }
                </div>
                {
                  stateSelectedRiskAnalisysAndActionItem?.remedy ?
                    <>
                      <h4>
                        <TwKey textKey="10271" />
                        &nbsp;
                        <InfoText textKey={ appState.currentChapterSectionTypeWithUrl.chapterSectionType.RiskAnalisysAndActionTasksInfoTextKey }/>
                      </h4>
                      <Table>
                        <TableHead style={{ backgroundColor: "#FFFFFF" }}>
                          <TableRow  style={{ backgroundColor: "#FFFFFF" }}>
                            <TableCell  style={{ backgroundColor: "#FFFFFF" }}>
                              <div className="textWithAlignedIcon">
                                <b><TwKey textKey="10005" />
                                <RequiredField />
                                </b>
                                <InfoText textKey={ appState.currentChapterSectionTypeWithUrl.chapterSectionType.RiskAnalisysAndActionTaskInfoTextKey } />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="textWithAlignedIcon">
                                <b><TwKey textKey="10006" /></b>
                                <InfoText textKey="10229"/>
                              </div>
                            </ TableCell>
                            <TableCell>
                              <div className="textWithAlignedIcon">
                                <b><TwKey textKey="10007" /></b>
                                <InfoText textKey="10230"/>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="textWithAlignedIcon">
                                <b><TwKey textKey="10008" /></b>
                                <InfoText textKey="10231"/>
                              </div>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            stateSelectedRiskAnalisysAndActionItem?.riskAnalisysAndActionTaskItems?.map(( riskAnalisysAndActionTaskItem, idx ) => (
                              <TableRow key={idx} style={{verticalAlign: "Middle"}}>
                                <TableCell>
                                  {
                                    stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                      <TextFieldWithPlaceholder
                                        placeholderTextKey="10123"
                                        value={ stateEditRiskAnalisysAndActionTaskItem.task }
                                        onChange={(event) => setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, task: event.target.value })}
                                        disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                      />
                                    :
                                      riskAnalisysAndActionTaskItem.task
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                      <TextFieldWithPlaceholder
                                        placeholderTextKey="10124"
                                        value={ stateEditRiskAnalisysAndActionTaskItem.responsible }
                                        onChange={(event) => setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, responsible: event.target.value })}
                                        disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                      />
                                    :
                                      riskAnalisysAndActionTaskItem.responsible
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                      stateEditRiskAnalisysAndActionTaskItem.ongoing ?
                                      <IconButton
                                        className="checkBoxButtonNoMarginOrPadding"
                                        onClick={(event) => setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, ongoing: false })}
                                        disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                      >
                                        <CheckBoxOutlined className="checkBoxColor" />
                                      </IconButton>
                                      :
                                      <IconButton
                                        className="checkBoxButtonNoMarginOrPadding"
                                        onClick={(event) => setStateEditRiskAnalisysAndActionTaskItem({ ...stateEditRiskAnalisysAndActionTaskItem, ongoing: true })}
                                        disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                      >
                                        <CheckBoxOutlineBlank className="checkBoxColor" />
                                      </IconButton>
                                    :
                                      riskAnalisysAndActionTaskItem.ongoing ?
                                        <CheckBoxOutlined className="checkBoxColorDisabled" />
                                      :
                                        <CheckBoxOutlineBlank className="checkBoxColorDisabled" />
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                      stateEditRiskAnalisysAndActionTaskItem.ongoing ?
                                        ""
                                      :
                                        <TextField
                                          variant="outlined"
                                          color="primary"
                                          type="date"
                                          size="small"
                                          value={stateEditRiskAnalisysAndActionTaskItem.followUpDate}
                                          onChange={(event) => setStateEditRiskAnalisysAndActionTaskItem( { ...stateEditRiskAnalisysAndActionTaskItem, followUpDate: event.target.value })}
                                          disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                        />
                                    :
                                      riskAnalisysAndActionTaskItem.ongoing ?
                                        ""
                                      :
                                        riskAnalisysAndActionTaskItem.followUpDate
                                  }
                                </TableCell>
                                <TableCell  style={{ textAlign: "Right"}}>
                                  {
                                    stateEditRiskAnalisysAndActionTaskItem?.id === riskAnalisysAndActionTaskItem.id ?
                                      <>
                                        <FormControl>
                                          <SaveButton
                                            buttonTextKey="10109"
                                            toolTipTitleTextKey="10110"
                                            disabled={ stateEditRiskAnalisysAndActionTaskItem.task !== "" ? false : true }
                                            onClick={ async () => await saveEditedRiskAnalisysAndActionTaskItemButtonOnClick(riskAnalisysAndActionTaskItem.id) }
                                          />
                                        </FormControl>
                                        &nbsp;
                                        <FormControl>
                                          <CancelButton
                                            buttonTextKey="10111"
                                            toolTipTitleTextKey="10112"
                                            onClick={ async () => await cancelEditRiskAnalisysAndActionTaskItemButtonOnClick() }
                                          />
                                        </FormControl>
                                      </>
                                    :
                                      <>
                                        <FormControl>
                                          <EditButton
                                            buttonTextKey = "10095"
                                            toolTipTitleTextKey = "10096"
                                            onClick={ async () => await editRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionTaskItem.id) }
                                            disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                          />
                                        </FormControl>
                                        &nbsp;
                                        <FormControl>
                                          <DeleteButton
                                            buttonTextKey = "10097"
                                            toolTipTitleTextKey = "10098"
                                            onClick={ () => PopupConfirmYesNoWithValue( "10113", riskAnalisysAndActionTaskItem.task, async () => await deleteRiskAnalisysAndActionTaskItemButtonOnClick( riskAnalisysAndActionTaskItem.id )) }
                                            disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                          />
                                        </FormControl>
                                      </>
                                  }
                                </TableCell>
                              </TableRow>
                            ))
                          }
                          <TableRow style={{verticalAlign: "Middle"}}>
                            <TableCell>
                              <TextFieldWithPlaceholder
                                placeholderTextKey="10121"
                                value={ stateNewRiskAnalisysAndActionTaskItem.task }
                                onChange={(event) => setStateNewRiskAnalisysAndActionTaskItem( { ...stateNewRiskAnalisysAndActionTaskItem, task: event.target.value })}
                                disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                              />
                            </TableCell>
                            <TableCell>
                              <TextFieldWithPlaceholder
                                placeholderTextKey="10122"
                                value={ stateNewRiskAnalisysAndActionTaskItem.responsible }
                                onChange={(event) => setStateNewRiskAnalisysAndActionTaskItem( { ...stateNewRiskAnalisysAndActionTaskItem, responsible: event.target.value })}
                                disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                              />
                            </TableCell>
                            <TableCell>
                              {
                                stateNewRiskAnalisysAndActionTaskItem.ongoing ?
                                    <IconButton
                                      className="checkBoxButtonNoMarginOrPadding"
                                      onClick={(event) => setStateNewRiskAnalisysAndActionTaskItem( { ...stateNewRiskAnalisysAndActionTaskItem, ongoing: false })}
                                      disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                    >
                                      <CheckBoxOutlined className="checkBoxColor"/>
                                    </IconButton>
                                  :
                                  <IconButton
                                    className="checkBoxButtonNoMarginOrPadding"
                                    onClick={(event) => setStateNewRiskAnalisysAndActionTaskItem({ ...stateNewRiskAnalisysAndActionTaskItem, ongoing: true })}
                                    disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                  >
                                    <CheckBoxOutlineBlank className="checkBoxColor" />
                                  </IconButton>
                              }
                            </TableCell>
                            <TableCell>
                              {
                                stateNewRiskAnalisysAndActionTaskItem.ongoing ?
                                  ""
                                :
                                  <TextField
                                    variant="outlined"
                                    color="primary"
                                    type="date"
                                    size="small"
                                    value={stateNewRiskAnalisysAndActionTaskItem.followUpDate}
                                    onChange={(event) => setStateNewRiskAnalisysAndActionTaskItem( { ...stateNewRiskAnalisysAndActionTaskItem, followUpDate: event.target.value })}
                                    disabled={stateSelectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                  />
                              }
                            </TableCell>
                            <TableCell style={{ textAlign: "Right"}}>
                              <FormControl>
                                <AddButton
                                  buttonTextKey = "10099"
                                  toolTipTitleTextKey = "10100"
                                  disabled={ stateNewRiskAnalisysAndActionTaskItem.task !== "" ? false : true }
                                  onClick={ async () => await addNewRiskAnalisysAndActionTaskItemOnClick() }
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  :
                    ""
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RiskAnalisysAndAction;