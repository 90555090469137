import { v4 as uuidv4 } from 'uuid';
import { PreparationItem } from "Classes/UserData";

/**
 * Get ActiveRiskAnalisysAndActionYear from currentRiskAnalisysAndActionYears.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @returns 
 */
export function getActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears ){
  //Get the index of the active year.
  const indexOfSelectedRiskAnalisysAndActionYear = currentRiskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);
  
  if( indexOfSelectedRiskAnalisysAndActionYear > -1 ){
    return JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears[indexOfSelectedRiskAnalisysAndActionYear] ) );
  } else {
    throw new Error("Critical error! No year is set as active!");
  }
}

/**
 * Get Preparations from currentRiskAnalisysAndActionYear in active RiskAnalisysAndActionYear
 * @param {*} currentRiskAnalisysAndActionYears 
 * @returns 
 */
export function getPreparationsFromActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears ){
  //Get the index of the active year.
  const indexOfSelectedRiskAnalisysAndActionYear = currentRiskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);
  
  if( indexOfSelectedRiskAnalisysAndActionYear > -1 ){
    return JSON.parse( JSON.stringify(currentRiskAnalisysAndActionYears[indexOfSelectedRiskAnalisysAndActionYear].preparations ) );
  } else {
    throw new Error("Critical error! No year is set as active!");
  }
}

/**
 * Get RiskAnalisysAndAction of TypeId from currentRiskAnalisysAndActionYear in active RiskAnalisysAndActionYear
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} riskAnalisysAndActionTypeId 
 * @returns 
 */
export function getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, riskAnalisysAndActionTypeId ){
  //Get the index of the active year.
  const activeOfSelectedRiskAnalisysAndActionYear = currentRiskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);
  
  if( activeOfSelectedRiskAnalisysAndActionYear > -1 ){
    return JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears[ activeOfSelectedRiskAnalisysAndActionYear ].riskAnalisysAndActions[riskAnalisysAndActionTypeId] ) );
  } else {
    throw new Error("Critical error! No year is set as active!");    
  }
}

/**
 * Add a RiskAnalisysAndActionYear to the array of RistAnalisysAndActionYears. 
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} riskAnalisysAndActionYear 
 * @returns 
 */
export async function addRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, riskAnalisysAndActionYear ){
  if( currentRiskAnalisysAndActionYears ){
    const riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );

    riskAnalisysAndActionYearsToUpdate.push(riskAnalisysAndActionYear);

    return riskAnalisysAndActionYearsToUpdate;
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * update current RiskAnlisysAndActionYear.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} riskAnalisysAndActionYearId 
 * @param {*} updatedRiskAnalisysAndActionYear
 */
export async function updateActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, riskAnalisysAndActionYearId, updatedRiskAnalisysAndActionYear ){
  if( currentRiskAnalisysAndActionYears ){
    const riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );
    
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.id === riskAnalisysAndActionYearId);

    riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear] = updatedRiskAnalisysAndActionYear;

    return riskAnalisysAndActionYearsToUpdate;
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update the Active RiskAnalisysAndActionYear
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} authors 
 * @returns 
 */
export async function updateActiveRiskAnalisysAndActionYearAuthors( currentRiskAnalisysAndActionYears, authors ) {
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );

    //Get the index of the active year.
    var indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Set the author.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].authors = authors;

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
};

/**
 * Add a preparation item to Active RiskAnalisys and action year.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} newPreparationItem 
 */
export async function addPreparationItemToActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, newPreparationItem ){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );
  
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Add the preparation to the active year.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].preparations.push(newPreparationItem);

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Add a preparation item to Active RiskAnalisys and action year.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} newPreparationItem 
 */
export async function addPreparationItemsFromGeneralPreparationItemTemplatessToActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, generalPreparationItemTemplates ){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );
  
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      for( const generalPreparationItemTemplate of generalPreparationItemTemplates ){
        if( generalPreparationItemTemplate.name !== "" ){
          const newPreparationItem = new PreparationItem(uuidv4(), generalPreparationItemTemplate.name, false, generalPreparationItemTemplate.order );

          //Add the preparation to the active year.
          riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].preparations.push(newPreparationItem);
        }
      }

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update preparation item in the Active RiskAnalisys and action year.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} preparationItemId 
 * @param {*} updatedPreparationItem 
 */
export async function updatePreparationItemInActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, updatedPreparationItem ){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );

    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex( (riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the index of the selected preparation.
      const preparationItemIndex = riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].preparations.findIndex((preparationItem => preparationItem.id === updatedPreparationItem.id ));
            
      //Put it back into the state.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].preparations[preparationItemIndex] = updatedPreparationItem;

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Delete preparation item in the Active RiskAnalisys and action year.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} preparationItemToDeleteId 
 * @returns 
 */
export async function deletePreparationItemInActiveRiskAnalisysAndActionYear(currentRiskAnalisysAndActionYears, preparationItemToDeleteId){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );
    
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      const preparations = riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].preparations;
  
      //Filter out the preparationItem to be deleted.
      const updatedPreparations = preparations.filter(( preparationItem) => preparationItem.id !== preparationItemToDeleteId );

      //Put it back into the state.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].preparations = updatedPreparations;

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update RisksDefinedAndAnalyzed in RiskAnalisysAndActtion of type riskAnalisysAndActionItemTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} riskAnalisysAndActionItemTypeId 
 * @param {*} risksDefinedAndAnalyzed 
 * @returns 
 */
export async function updateRisksDefinedAndAnalyzedInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, riskAnalisysAndActionItemTypeId, risksDefinedAndAnalyzed ){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );

    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId];
            
      //Set the correct value
      riskAnalisysAndActionOfYearAndType.risksDefinedAndAnalyzed = risksDefinedAndAnalyzed;

      //Put it back into the state.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Add RiskAnalisysAndActionItem in RiskAnalisysAndActtion of TypeId riskAnalisysAndActionItemTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} riskAnalisysAndActionItemTypeId 
 * @param {*} riskAnalisysAndActionItem 
 * @returns 
 */
export async function addRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear(currentRiskAnalisysAndActionYears, riskAnalisysAndActionItemTypeId, riskAnalisysAndActionItem){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );

    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId];
            
      //Push the new item.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.push(riskAnalisysAndActionItem);

      //Put it back into the state.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update RiskAnalisysAndActionItem in RiskAnalisysAndActtion of typeId riskAnalisysAndActionItemTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} riskAnalisysAndActionItemTypeId 
 * @param {*} updatedRiskAnalisysAndActionItem 
 * @returns 
 */
export async function updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, riskAnalisysAndActionItemTypeId, updatedRiskAnalisysAndActionItem ){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );
  
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId];

      //Get the index of the item we want to update.
      const indexOfRiskAnalisysAndActionItemToUpdate = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.findIndex(( riskAnalisysAndActionItem ) => riskAnalisysAndActionItem.id === updatedRiskAnalisysAndActionItem.id);

      //Replace at the index we found.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItemToUpdate] = updatedRiskAnalisysAndActionItem;

      //Put it back in the state.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Delete RiskAnalisysAndActionItem in RiskAnalisysAndActtion of type id riskAnalisysAndActionItemTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} currentRiskAnalisysAndActionYears 
 * @param {*} riskAnalisysAndActionItemTypeId 
 * @param {*} riskAnalisysAndActionItemToDeleteId 
 * @returns 
 */
export async function deleteRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( currentRiskAnalisysAndActionYears, riskAnalisysAndActionItemTypeId, riskAnalisysAndActionItemToDeleteId ){
  if( currentRiskAnalisysAndActionYears ){
    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( currentRiskAnalisysAndActionYears ) );
  
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYearsToUpdate.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId];

      //Filter on the supplied Id so that we get a new array of riskAnalisysAndActionItems that dont have the supplied item.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.filter(( riskAnalisysAndActionItem) => riskAnalisysAndActionItem.id !== riskAnalisysAndActionItemToDeleteId );

      //Put it back in the state.
      riskAnalisysAndActionYearsToUpdate[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionItemTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYearsToUpdate;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}