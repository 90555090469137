import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { AuthenticatedUrls, ChapterSectionTypeUrls } from "Routes/Urls";
import { Home, Article, Inventory, Assignment, Check, AssignmentInd, AssignmentTurnedIn, CloseIcon } from "@mui/icons-material";
import { TwKey } from "Components/StandardTextComponentsWithTranslation";
import useForceUpdate from 'use-force-update';
import { setAppStateSetting } from 'Features/AppState/AppStateSlice';
import { useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery } from 'API/UserDataStorageAPI';
import { getActiveRiskAnalisysAndActionYear } from 'API/SelectOrModifyUserDataStorageItems';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import InfoIcon from '@mui/icons-material/Info';
import likaPlanLogo from "Assets/Images/LikaPlanLogo.png";
import Loading from './Loading';


const SideMenu = () => {
  const appState = useSelector((state) => state.settings.appState);
  
  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );

  const dispatch = useDispatch();

  const forceUpdate = useForceUpdate();

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    dispatch( setAppStateSetting( { sideMenuOpen: false } ));
  };

  async function pinSideMenu(){
    console.log("Pinning side menu");
    dispatch( setAppStateSetting( { sideMenuPinned: true } ));
    forceUpdate();
  }

  async function unPinSideMenu(){
    dispatch( setAppStateSetting( { sideMenuPinned: false } ));
    forceUpdate();
  }

  // Inside side menu functions start here.

  if( riskAnalisysAndActionYearsIsLoading ){
    return <Loading />;
  } else {
    const activeRiskAnalisysAndActionYear = getActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

    // Get Welcome menu items
    let welcomeMenuItemsWithDone =[];

    // Get startMenuItems with done.
    let startMenuItemsWithDone = [];

    // Get menuitems with done.
    let menuItemWithDone = [];

    // Get executionMenuItems with done.
    let executionMenuItemsWithDone = [];

    const anchor = 'left';

    if( appState.rerenderSideMenu ){
      dispatch( setAppStateSetting( { rerenderSideMenu: false } ));
      forceUpdate();
    }

    if (!appState.sideMenuOpen) {
      return null;
    }

    //Get what to put in the menu.
    for ( let currentChapterSection of Object.values(ChapterSectionTypeUrls) ) {
      let pagePath = null;
      let done = false;

      //Check if we are on Welcome in Likabehandlingsplan.
      if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.welcome.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.welcome.path;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        welcomeMenuItemsWithDone.push(menuItemWithDoneRow);
      } else if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.introduction.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.introduction.path;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        startMenuItemsWithDone.push(menuItemWithDoneRow);
      } else if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.materials.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.materials.path;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        startMenuItemsWithDone.push(menuItemWithDoneRow);
      } else if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.preparations.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.preparations.path;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        startMenuItemsWithDone.push(menuItemWithDoneRow);
      } else if ( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.equalTreatmentPlanStatus.chapterSectionType ) {
        pagePath = AuthenticatedUrls.content.subPages.equalTreatmentPlanStatus.path;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        executionMenuItemsWithDone.push(menuItemWithDoneRow);
      } else if ( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.riskActionsAndFollowUp.chapterSectionType ) {
        pagePath = AuthenticatedUrls.content.subPages.riskActionsAndFollowUp.path;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        executionMenuItemsWithDone.push(menuItemWithDoneRow);
      } else if ( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.equalTreatmentPlanReport.chapterSectionType ) {
        pagePath = AuthenticatedUrls.content.subPages.equalTreatmentPlanReport.path;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        executionMenuItemsWithDone.push(menuItemWithDoneRow);
      } else {
        pagePath = AuthenticatedUrls.content.subPages.chapterSection.subPages.risks.path.replace(":chapterSection", currentChapterSection.subPath);
        done = activeRiskAnalisysAndActionYear.riskAnalisysAndActions[currentChapterSection.chapterSectionType.Name].risksDefinedAndAnalyzed;

        let menuItemWithDoneRow = {
          path: pagePath, 
          headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
          chapterSectionType: currentChapterSection.chapterSectionType,
          class: currentChapterSection.chapterSectionType === appState.currentChapterSectionTypeWithUrl?.chapterSectionType ? "sideMenuItemCurrent" : "sideMenuItem",
          done: done,
        }

        menuItemWithDone.push(menuItemWithDoneRow);
      }
    }
    
    const SideMenuItems = () => {
      return (
        <div className="sideMenu">
          <div style={{ marginTop: "15px", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "cem"}}>
              <Link to={AuthenticatedUrls.content.subPages.welcome.path}>
                <div style={{ marginTop: "5px" }}>
                  <img src={likaPlanLogo} style={{ width: "90%", height: "auto" }} alt="Likabehandlingsplans logo i grönt." />
                </div>
              </Link>
            {/* <div style={{ marginRight: "5px", width: "40px" }}>
              {
                appState.sideMenuPinned ?
                  <UnPinButton buttonTextKey="10292" toolTipTitleTextKey="10293" onClick={ async () => await unPinSideMenu() } />
                :
                  <PinButton buttonTextKey="10290" toolTipTitleTextKey="10291" onClick={ async () => await pinSideMenu() } />
              }
            </div> */}
          </div>
          <div>
          <div style={{ marginTop: "0px"}}>
              {welcomeMenuItemsWithDone.map((page, idx) => (
                <Link key={idx} to={page.path} style={{ textDecoration: "none", color: "black" }} onClick={ () => window.scrollTo(0, 0) }>
                  <div className={page.class}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      { page.chapterSectionType === ChapterSectionTypeUrls.welcome.chapterSectionType ? <><Home style={{ fontSize: "18px" }} /><span>&nbsp;</span></> : "" }
                      <p><TwKey textKey={page.headerTextKey} /></p>
                    </div>
                    <div>
                      { page.done ? <Check /> : "" }
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div style={{ marginTop: "15px" }}>
              <h1 className="sideMenuTitle" style={{ marginTop: "25px" }}><TwKey textKey="10287"/></h1>
            </div>
            <div style={{ marginTop: "0px"}}>
              {startMenuItemsWithDone.map((page, idx) => (
                <Link key={idx} to={page.path} style={{ textDecoration: "none", color: "black" }} onClick={ () => window.scrollTo(0, 0) }>
                  <div className={page.class}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      { page.chapterSectionType === ChapterSectionTypeUrls.introduction.chapterSectionType ? <><InfoIcon style={{ fontSize: "18px" }} /><span>&nbsp;</span></> : "" }
                      { page.chapterSectionType === ChapterSectionTypeUrls.materials.chapterSectionType ? <><Article style={{ fontSize: "18px" }} /><span>&nbsp;</span></> : "" }
                      { page.chapterSectionType === ChapterSectionTypeUrls.preparations.chapterSectionType ? <><Inventory style={{ fontSize: "18px" }} /><span>&nbsp;</span></> : "" }
                      <p><TwKey textKey={page.headerTextKey} /></p>
                    </div>
                    <div>
                      { page.done ? <Check /> : "" }
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <Divider/>
            <div style={{ marginTop: "15px"}}>
              <h1 className="sideMenuTitle"><TwKey textKey="10288"/></h1>
                {menuItemWithDone.map((page, idx) => (
                  <Link key={idx} to={page.path} style={{ textDecoration: "none", color: "black" }} onClick={ () => window.scrollTo(0, 0) } >
                    <div className={page.class}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p><TwKey textKey={page.headerTextKey} /></p>
                      </div>
                      <div>
                        { page.done ? <Check /> : "" }
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            <Divider/>
            <div style={{ marginTop: "15px"}}>
              <h1 className="sideMenuTitle"><TwKey textKey="10289"/></h1>
              {executionMenuItemsWithDone.map((page, idx) => (
                <Link key={idx} to={page.path} style={{ textDecoration: "none", color: "black" }} onClick={ () => window.scrollTo(0, 0) }>
                  <div className={page.class}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      { page.chapterSectionType === ChapterSectionTypeUrls.riskActionsAndFollowUp.chapterSectionType ? <><AssignmentInd style={{ fontSize: "18px" }} /><span>&nbsp;</span></> : "" }
                      { page.chapterSectionType === ChapterSectionTypeUrls.equalTreatmentPlanStatus.chapterSectionType ? <><AssignmentTurnedIn style={{ fontSize: "18px" }} /><span>&nbsp;</span></> : "" }
                      { page.chapterSectionType === ChapterSectionTypeUrls.equalTreatmentPlanReport.chapterSectionType ? <><Assignment style={{ fontSize: "18px" }} /><span>&nbsp;</span></> : "" }
                      <p><TwKey textKey={page.headerTextKey} /></p>
                    </div>
                    <div>
                      { page.done ? <Check /> : "" }
                    </div>
                  </div>
                </Link>
              ))}
            </div>
        </div>
      );
    }

    return (
      <>
        {
          appState.sideMenuPinned ? 
            <SideMenuItems />
          :
            <Drawer
              anchor={anchor}
              open={appState.sideMenuOpen}
              onClose={toggleDrawer()}
              variant="temporary"
            >
              <SideMenuItems />
            </Drawer>
        }
      </>
    );
  }
}


export default SideMenu;